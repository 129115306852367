// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { CssBaseline } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store.ts';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarProvider } from 'notistack';

export const apiUrl = import.meta.env.VITE_API_URL;
export const dateLocale = import.meta.env.VITE_DATE_LOCALE || 'en';
const renderApp = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <CssBaseline />
          {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={7000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <App />
          </SnackbarProvider>
          {/*</LocalizationProvider>*/}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

createRoot(document.getElementById('root')!).render(renderApp());
