import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Paper, Button, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import i18next from '../../utilis/i18next';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';
import { useDispatch, useSelector } from 'react-redux';
import { confirmEmail } from '../../store/slices/authSlice';
import { apiUrl } from '../../main';
import axiosInstance from '../../utilis/axios';

const AccountConfirmationPage: React.FC = () => {
  useDocumentTitle(i18next.t('Views.AccountConfirmationPage.Title'));
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');
      const userId = query.get('userId');

      if (!token || !userId) {
        setIsLoading(false);
        setIsSuccess(false);
        setErrorMessage(i18next.t('Views.AccountConfirmationPage.Errors.InvalidLink'));
        return;
      }

      try {
        const resultAction = await dispatch(confirmEmail({ token, userId }));
        if (confirmEmail.fulfilled.match(resultAction)) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
          if (resultAction.error) {
            setErrorMessage(
              resultAction.error.message || i18next.t('Views.AccountConfirmationPage.Errors.GenericError')
            );
          } else {
            setErrorMessage(i18next.t('Views.AccountConfirmationPage.Errors.GenericError'));
          }
        }
      } catch (error) {
        setIsSuccess(false);
        setErrorMessage(i18next.t('Views.AccountConfirmationPage.Errors.GenericError'));
      } finally {
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [location.search, dispatch]);

  const handleGoToLogin = () => {
    navigate('/login');
  };

  const handleGoToHome = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container maxWidth="md" sx={{ py: 4, position: 'relative', zIndex: 1 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            backgroundColor: 'rgba(18, 18, 18, 0.9)',
            border: '1px solid rgba(212, 175, 55, 0.3)',
            borderRadius: '8px',
            textAlign: 'center',
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h3" component="h1" sx={{ color: '#D4AF37', mb: 3 }}>
              {i18next.t('Views.AccountConfirmationPage.Title')}
            </Typography>

            {isLoading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
                <CircularProgress sx={{ color: '#D4AF37', mb: 2 }} />
                <Typography variant="body1" sx={{ color: '#fff' }}>
                  {i18next.t('Views.AccountConfirmationPage.Processing')}
                </Typography>
              </Box>
            ) : isSuccess ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
                <CheckCircleOutlineIcon sx={{ color: '#4CAF50', fontSize: 60, mb: 2 }} />
                <Typography variant="h5" sx={{ color: '#4CAF50', mb: 2 }}>
                  {i18next.t('Views.AccountConfirmationPage.Success.Title')}
                </Typography>
                <Typography variant="body1" sx={{ color: '#fff', mb: 4 }}>
                  {i18next.t('Views.AccountConfirmationPage.Success.Message')}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ 
                    backgroundColor: '#D4AF37', 
                    '&:hover': { backgroundColor: '#B4941E' }, 
                    mb: 2,
                    px: 4 
                  }}
                  onClick={handleGoToLogin}
                >
                  {i18next.t('Views.AccountConfirmationPage.Buttons.GoToLogin')}
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 4 }}>
                <ErrorOutlineIcon sx={{ color: '#F44336', fontSize: 60, mb: 2 }} />
                <Typography variant="h5" sx={{ color: '#F44336', mb: 2 }}>
                  {i18next.t('Views.AccountConfirmationPage.Error.Title')}
                </Typography>
                <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
                  {errorMessage}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ 
                    backgroundColor: '#D4AF37', 
                    '&:hover': { backgroundColor: '#B4941E' },
                    mb: 2,
                    px: 4 
                  }}
                  onClick={handleGoToHome}
                >
                  {i18next.t('Views.AccountConfirmationPage.Buttons.GoToHome')}
                </Button>
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default AccountConfirmationPage;
