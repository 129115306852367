export const countryCodes = [
  { code: '+47', country: 'NO', name: 'Norway' },
  { code: '+45', country: 'DK', name: 'Denmark' },
  { code: '+46', country: 'SE', name: 'Sweden' },
  { code: '+358', country: 'FI', name: 'Finland' },
  { code: '+354', country: 'IS', name: 'Iceland' },
  { code: '+44', country: 'GB', name: 'United Kingdom' },
  { code: '+1', country: 'US', name: 'United States' },
  { code: '+49', country: 'DE', name: 'Germany' },
  { code: '+33', country: 'FR', name: 'France' },
  { code: '+39', country: 'IT', name: 'Italy' },
  { code: '+34', country: 'ES', name: 'Spain' },
  { code: '+31', country: 'NL', name: 'Netherlands' },
  { code: '+387', country: 'BA', name: 'Bosnia and Herzegovina' },
  { code: '+43', country: 'AT', name: 'Austria' },
  { code: '+32', country: 'BE', name: 'Belgium' },
  { code: '+41', country: 'CH', name: 'Switzerland' },
  { code: '+48', country: 'PL', name: 'Poland' },
  { code: '+351', country: 'PT', name: 'Portugal' },
  { code: '+353', country: 'IE', name: 'Ireland' },
  { code: '+36', country: 'HU', name: 'Hungary' },
  { code: '+30', country: 'GR', name: 'Greece' },
  { code: '+420', country: 'CZ', name: 'Czech Republic' },
  { code: '+421', country: 'SK', name: 'Slovakia' },
  { code: '+386', country: 'SI', name: 'Slovenia' },
  { code: '+385', country: 'HR', name: 'Croatia' },
  { code: '+359', country: 'BG', name: 'Bulgaria' },
  { code: '+40', country: 'RO', name: 'Romania' },
  { code: '+370', country: 'LT', name: 'Lithuania' },
  { code: '+371', country: 'LV', name: 'Latvia' },
  { code: '+372', country: 'EE', name: 'Estonia' },
];
