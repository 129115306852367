import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Paper, TextField, Button, Alert, CircularProgress } from '@mui/material';
import i18next from '../../utilis/i18next';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';
import axiosInstance from '../../utilis/axios';
import { useNavigate, useLocation } from 'react-router-dom';

const ResetPasswordPage: React.FC = () => {
  useDocumentTitle(i18next.t('Views.ResetPasswordPage.Title'));
  const navigate = useNavigate();
  const location = useLocation();
  
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<{
    password?: string;
    confirmPassword?: string;
  }>({});

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tokenParam = query.get('token');

    if (tokenParam) {
      setToken(tokenParam);
      
      // Extract email from JWT token
      try {
        const tokenParts = tokenParam.split('.');
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          if (payload.email) {
            setEmail(payload.email);
          }
        }
      } catch (error) {
        console.error('Error parsing JWT token:', error);
      }
    }
  }, [location.search]);

  const validateForm = () => {
    const errors: {
      password?: string;
      confirmPassword?: string;
    } = {};
    let isValid = true;

    // Password validation
    if (!newPassword) {
      errors.password = i18next.t('Views.ResetPasswordPage.Errors.PasswordRequired');
      isValid = false;
    } else if (newPassword.length < 8) {
      errors.password = i18next.t('Views.ResetPasswordPage.Errors.PasswordMinLength');
      isValid = false;
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(newPassword)) {
      errors.password = i18next.t('Views.ResetPasswordPage.Errors.PasswordRequirements');
      isValid = false;
    }

    // Confirm password validation
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = i18next.t('Views.ResetPasswordPage.Errors.PasswordMismatch');
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setValidationErrors({});

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      await axiosInstance.post('/auth/reset-password', {
        email,
        token,
        newPassword
      });
      setIsSuccess(true);
    } catch (err: any) {
      setError(
        err.response?.data?.message || 
        i18next.t('Views.ResetPasswordPage.Errors.Generic')
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackToLogin = () => {
    navigate('/login');
  };

  if (!token || !email) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          position: 'relative',
          background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            backgroundColor: 'rgba(18, 18, 18, 0.9)',
            border: '1px solid rgba(212, 175, 55, 0.3)',
            borderRadius: '8px',
            textAlign: 'center',
            maxWidth: '500px',
            width: '90%',
          }}
        >
          <Typography variant="h5" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.ResetPasswordPage.InvalidLink.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 4 }}>
            {i18next.t('Views.ResetPasswordPage.InvalidLink.Message')}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/forgot-password')}
            sx={{ 
              backgroundColor: '#D4AF37', 
              '&:hover': { backgroundColor: '#B4941E' },
              px: 4,
              mr: 2
            }}
          >
            {i18next.t('Views.ResetPasswordPage.TryAgainButton')}
          </Button>
          <Button
            variant="outlined"
            onClick={handleBackToLogin}
            sx={{ 
              borderColor: '#D4AF37',
              color: '#D4AF37',
              '&:hover': { 
                borderColor: '#FFD700',
                color: '#FFD700'
              },
              px: 4 
            }}
          >
            {i18next.t('Views.ResetPasswordPage.BackToLogin')}
          </Button>
        </Paper>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          pt: { xs: 4, sm: 8 },
          pb: 4,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          mt={10}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            animation: 'fadeIn 0.8s ease-out',
            '@keyframes fadeIn': {
              from: { opacity: 0, transform: 'translateY(20px)' },
              to: { opacity: 1, transform: 'translateY(0)' },
            },
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 4,
              fontWeight: 600,
              textAlign: 'center',
              background:
                'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundSize: '200% auto',
              animation: 'shine 3s linear infinite, slideDown 0.8s ease-out',
              '@keyframes shine': {
                to: {
                  backgroundPosition: '200% center',
                },
              },
              '@keyframes slideDown': {
                from: {
                  opacity: 0,
                  transform: 'translateY(-20px)',
                },
                to: {
                  opacity: 1,
                  transform: 'translateY(0)',
                },
              },
            }}
          >
            {i18next.t('Views.ResetPasswordPage.Title')}
          </Typography>

          {!isSuccess ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                width: '100%',
                background: 'rgba(0, 0, 0, 0.6)',
                backdropFilter: 'blur(10px)',
                p: 3,
                borderRadius: 1,
                border: '1px solid rgba(212, 175, 55, 0.3)',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
                position: 'relative',
                overflow: 'hidden',
                animation: 'formAppear 0.8s ease-out 0.2s both',
                '@keyframes formAppear': {
                  from: {
                    opacity: 0,
                    transform: 'scale(0.95)',
                    filter: 'blur(5px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'scale(1)',
                    filter: 'blur(0)',
                  },
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '1px',
                  background:
                    'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.5), transparent)',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '1px',
                  background:
                    'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.5), transparent)',
                },
              }}
            >
              <Typography variant="body1" sx={{ color: '#fff', mb: 3, textAlign: 'center' }}>
                {i18next.t('Views.ResetPasswordPage.Instructions')}
              </Typography>

              {error && (
                <Alert severity="error" sx={{ mb: 2, backgroundColor: 'rgba(211, 47, 47, 0.95)', color: '#fff' }}>
                  {error}
                </Alert>
              )}

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18next.t('Views.ResetPasswordPage.NewPassword')}
                type="password"
                id="password"
                autoComplete="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!!validationErrors.password}
                helperText={validationErrors.password}
                FormHelperTextProps={{ sx: { color: 'error.main' } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    color: '#fff',
                    '& fieldset': {
                      borderColor: 'rgba(212, 175, 55, 0.3)',
                      borderWidth: '1px',
                      transition: 'all 0.2s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D4AF37',
                      borderWidth: '1px',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D4AF37',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: '#D4AF37',
                    },
                  },
                  mb: 2,
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={i18next.t('Views.ResetPasswordPage.ConfirmPassword')}
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={!!validationErrors.confirmPassword}
                helperText={validationErrors.confirmPassword}
                FormHelperTextProps={{ sx: { color: 'error.main' } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    color: '#fff',
                    '& fieldset': {
                      borderColor: 'rgba(212, 175, 55, 0.3)',
                      borderWidth: '1px',
                      transition: 'all 0.2s ease',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D4AF37',
                      borderWidth: '1px',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D4AF37',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: '#D4AF37',
                    },
                  },
                  mb: 2,
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="outlined"
                disabled={isSubmitting}
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  borderColor: '#D4AF37',
                  color: '#D4AF37',
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'all 0.3s ease',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background:
                      'linear-gradient(120deg, transparent, rgba(212, 175, 55, 0.2), transparent)',
                    transform: 'translateX(-100%)',
                  },
                  '&:hover': {
                    borderColor: '#FFD700',
                    color: '#FFD700',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 4px 15px rgba(212, 175, 55, 0.2)',
                    '&::before': {
                      animation: 'shimmer 1.5s infinite',
                    },
                  },
                  '@keyframes shimmer': {
                    '100%': {
                      transform: 'translateX(100%)',
                    },
                  },
                  '&:active': {
                    transform: 'translateY(0)',
                  },
                  '&:disabled': {
                    borderColor: 'rgba(212, 175, 55, 0.3)',
                    color: 'rgba(212, 175, 55, 0.3)',
                  },
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} sx={{ color: '#D4AF37' }} />
                ) : (
                  i18next.t('Views.ResetPasswordPage.SubmitButton')
                )}
              </Button>

              <Button
                fullWidth
                variant="text"
                onClick={handleBackToLogin}
                sx={{
                  color: 'rgba(212, 175, 55, 0.7)',
                  '&:hover': {
                    color: '#D4AF37',
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {i18next.t('Views.ResetPasswordPage.BackToLogin')}
              </Button>
            </Box>
          ) : (
            <Paper
              elevation={3}
              sx={{
                p: 4,
                backgroundColor: 'rgba(18, 18, 18, 0.9)',
                border: '1px solid rgba(212, 175, 55, 0.3)',
                borderRadius: '8px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <Typography variant="h5" sx={{ color: '#4CAF50', mb: 2 }}>
                {i18next.t('Views.ResetPasswordPage.Success.Title')}
              </Typography>
              <Typography variant="body1" sx={{ color: '#fff', mb: 4 }}>
                {i18next.t('Views.ResetPasswordPage.Success.Message')}
              </Typography>
              <Button
                variant="contained"
                onClick={handleBackToLogin}
                sx={{ 
                  backgroundColor: '#D4AF37', 
                  '&:hover': { backgroundColor: '#B4941E' },
                  px: 4 
                }}
              >
                {i18next.t('Views.ResetPasswordPage.GoToLoginButton')}
              </Button>
            </Paper>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPasswordPage;
