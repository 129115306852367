import { Divider, Typography } from '@mui/material';

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{ color: '#D4AF37', fontWeight: 500, mb: 3 }}
      >
        {title}
      </Typography>
      <Divider sx={{ mb: 4, borderColor: 'rgba(212, 175, 55, 0.2)' }} />
    </>
  );
};
export default SectionTitle;
