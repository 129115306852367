import { Container, Grid, Snackbar, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { countryCodes } from '../../utils/countryCodes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  fetchUserProfile,
  updateNotificationPreferences,
  updateUserProfile,
} from '../../store/slices/authSlice';
import axiosInstance from '../../utilis/axios';
import { apiUrl } from '../../main';
import i18next from '../../utilis/i18next.ts';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import 'dayjs/locale/sv'; // Import Swedish locale
import 'dayjs/locale/en'; // Import English locale
import 'dayjs/locale/nb'; // Import Norwegian locale
import Header from '../../components/header';
import VerifyPhoneDialog from './components/VerifyPhoneDialog';
import PersonalInformation from './components/PersonalInformation';
import ProfileSummaryCard from './components/ProfileSummaryCard';
import NotificationPreferences from './components/NotificationPreferences';
import SecuritySettings from './components/SecuritySettings';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  dateOfBirth: string;
  profilePictureId?: string | null;
  phoneNumberConfirmed?: boolean;
}

const ProfilePage = () => {
  useDocumentTitle(i18next.t('Views.ProfilePage.Title', 'Profile'));
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  // React Hook Form setup
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
    setError,
    trigger,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      countryCode: '+47', // Default to Norway
      dateOfBirth: '',
      phoneNumberConfirmed: false,
    },
  });

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<'success' | 'error' | 'info'>(
    'success'
  );

  // Notification preferences state
  const [notificationSettings, setNotificationSettings] = useState(
    auth.user.notificationPreferences || {}
  );
  const [savingNotifications, setSavingNotifications] = useState(false);

  // Verification dialog state
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);

  // Use a ref to track if we've already fetched the profile
  const profileFetchedRef = useRef(false);

  useEffect(() => {
    if (auth.user && !profileFetchedRef.current) {
      console.log('Initial auth user data:', auth.user);

      // Set initial form data from auth state
      reset({
        firstName: auth.user.firstName || '',
        lastName: auth.user.lastName || '',
        email: auth.user.email || '',
        phoneNumber: auth.user.phoneNumber || '',
        countryCode: '+47', // Default
        dateOfBirth: auth.user.dateOfBirth || '',
        phoneNumberConfirmed: auth.user.phoneNumberConfirmed || false,
      });

      // Load notification preferences if available
      if (auth.user.notificationPreferences) {
        console.log(
          'Setting notification preferences from auth.user:',
          auth.user.notificationPreferences
        );
        setNotificationSettings(auth.user.notificationPreferences);
      }

      // Fetch additional user details only once
      fetchProfile();
      profileFetchedRef.current = true;
    }
  }, [auth.user, reset]); // Only depends on auth.user, not the entire auth object

  // dispatch is already declared above

  const fetchProfile = async () => {
    if (!auth.user) return;

    setLoading(true);
    try {
      const resultAction = await dispatch(fetchUserProfile());
      if (fetchUserProfile.fulfilled.match(resultAction)) {
        // Update form data with fetched user profile
        const userData = resultAction.payload;

        // Update notification preferences from fetched profile data
        if (userData.notificationPreferences) {
          console.log(
            'Setting notification preferences from profile fetch:',
            userData.notificationPreferences
          );
          setNotificationSettings(userData.notificationPreferences);
        }

        // Extract country code or set default
        let phoneNumber = userData.phoneNumber || '';
        let countryCode = '+47'; // Default to Norway

        // Handle phone number parsing
        if (phoneNumber) {
          // First check if it has a space separator
          const spaceIndex = phoneNumber.indexOf(' ');
          if (spaceIndex > 0) {
            countryCode = phoneNumber.substring(0, spaceIndex);
            phoneNumber = phoneNumber.substring(spaceIndex + 1);
          }
          // If no space, but it starts with a +, try to extract the country code
          else if (phoneNumber.startsWith('+')) {
            // Sort country codes by length (longest first) to ensure we match the most specific code
            const sortedCodes = [...countryCodes].sort(
              (a, b) => b.code.length - a.code.length
            );

            // Find the matching country code
            let foundCode = '';
            for (const country of sortedCodes) {
              if (
                phoneNumber.startsWith(country.code) &&
                country.code.length > foundCode.length
              ) {
                foundCode = country.code;
              }
            }

            if (foundCode) {
              countryCode = foundCode;
              phoneNumber = phoneNumber.substring(foundCode.length);
            }
          }
        }

        // Update form values with React Hook Form
        reset({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          email: userData.email || '',
          phoneNumber: phoneNumber,
          countryCode: countryCode,
          dateOfBirth: userData.dateOfBirth || '',
          profilePictureId: userData.profilePictureId,
          phoneNumberConfirmed: userData.phoneNumberConfirmed || false,
        });
      } else if (fetchUserProfile.rejected.match(resultAction)) {
        console.error('Failed to fetch profile:', resultAction.payload);
        showNotification('Failed to load profile data', 'error');
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
      showNotification('An error occurred while loading your profile', 'error');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: FormValues) => {
    setSaving(true);
    try {
      // Combine phone parts
      const fullPhoneNumber = `${data.countryCode}${data.phoneNumber}`;

      // Fields that should be updated
      const updateData = {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: fullPhoneNumber,
        dateOfBirth: data.dateOfBirth,
        profilePictureId: data.profilePictureId,
      };

      const resultAction = await dispatch(updateUserProfile(updateData));
      if (updateUserProfile.fulfilled.match(resultAction)) {
        showNotification('Profile updated successfully', 'success');
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      showNotification('Failed to update profile', 'error');
    } finally {
      setSaving(false);
    }
  };

  const showNotification = (
    msg: string,
    type: 'success' | 'error' | 'info'
  ) => {
    setMessage(msg);
    setMessageType(type);
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  // Handle phone verification
  const handleVerifyPhone = async () => {
    const phoneNumber = watch('phoneNumber');
    const countryCode = watch('countryCode');
    const phoneNumberConfirmed = watch('phoneNumberConfirmed');

    if (!phoneNumber || phoneNumberConfirmed) {
      showNotification('Please enter your phone number first', 'error');
      return;
    }

    try {
      // Show loading message
      showNotification('Sending verification code...', 'info');

      // Send request to initiate verification process
      await axiosInstance.post(`${apiUrl}/users/verify-phone`, {
        phoneNumber: `${countryCode}${phoneNumber}`,
      });

      // Open the verification dialog
      setVerifyDialogOpen(true);

      // Show success message
      showNotification(
        'Verification code sent to your WhatsApp account. Please check your messages.',
        'success'
      );
    } catch (error: any) {
      console.error('Error sending verification code:', error);
      showNotification(
        error.response?.data?.message ||
          'Failed to send verification code. Please try again later.',
        'error'
      );
    }
  };

  // Handle successful phone verification
  const handleVerificationSuccess = () => {
    // Update the phoneNumberConfirmed status in the form
    setValue('phoneNumberConfirmed', true);

    // Close the verification dialog
    setVerifyDialogOpen(false);

    // Show success message
    showNotification('Phone number verified successfully', 'success');
  };

  // Handle dialog close
  const handleCloseVerifyDialog = () => {
    setVerifyDialogOpen(false);
  };

  // Handle notification settings changes
  const handleNotificationSettingsChange = useCallback(
    (settings) => {
      console.log('handleNotificationSettingsChange called with:', settings);
      setNotificationSettings(settings);
      setSavingNotifications(true);

      // Save the notification preferences to backend
      dispatch(updateNotificationPreferences(settings))
        .unwrap()
        .then(() => {
          // Show success message
          setShowMessage(true);
          setMessage('Notification preferences updated successfully');
          setMessageType('success');
        })
        .catch((err) => {
          // Show error message
          setShowMessage(true);
          setMessage(
            err.message || 'Failed to update notification preferences'
          );
          setMessageType('error');
        })
        .finally(() => {
          setSavingNotifications(false);
        });
    },
    [dispatch]
  );

  // Handle profile image change with auto-submit
  const handleImageChange = async (imageId: string | null) => {
    // Update React Hook Form state
    setValue('profilePictureId', imageId);

    // If imageId is not null, auto-submit the form
    if (imageId) {
      setSaving(true);

      try {
        // Create updated form data with the new image ID
        const updatedFormData = {
          firstName: watch('firstName'),
          lastName: watch('lastName'),
          email: watch('email'),
          phoneNumber: watch('phoneNumber'),
          dateOfBirth: watch('dateOfBirth'),
          profilePictureId: imageId,
        };

        console.log('Submitting profile with new image:', updatedFormData);

        // Dispatch the update action
        const resultAction = await dispatch(updateUserProfile(updatedFormData));

        if (updateUserProfile.fulfilled.match(resultAction)) {
          showNotification('Profile picture updated successfully', 'success');
        } else {
          showNotification('Failed to update profile picture', 'error');
        }
      } catch (error) {
        console.error('Error updating profile with new image:', error);
        showNotification('Error updating profile picture', 'error');
      } finally {
        setSaving(false);
      }
    }
  };

  // Handle profile picture removal
  const handleRemoveProfilePicture = async () => {
    try {
      const profilePictureId = watch('profilePictureId');
      if (!profilePictureId) return;

      setSaving(true);

      try {
        // Delete the profile picture from the server
        await axiosInstance.delete(`${apiUrl}/files/${profilePictureId}`);
      } catch (error) {
        console.error('Error deleting profile picture:', error);
        showNotification('Failed to remove profile picture', 'error');
        return;
      }

      // Create a copy of the form data with profilePictureId set to null
      const updatedFormData = {
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        email: watch('email'),
        phoneNumber: `${watch('countryCode')}${watch('phoneNumber')}`,
        dateOfBirth: watch('dateOfBirth'),
        profilePictureId: null,
      };

      // Update React Hook Form state
      setValue('profilePictureId', null);

      console.log('Submitting updated profile data:', updatedFormData);

      // Automatically submit the form with the updated data
      const resultAction = await dispatch(updateUserProfile(updatedFormData));

      if (updateUserProfile.fulfilled.match(resultAction)) {
        // Update the React Hook Form data with the response
        const updatedData = resultAction.payload;

        // Extract country code or set default
        let phoneNumber = updatedData.phoneNumber || '';
        let countryCode = '+47'; // Default to Norway

        // Try to extract country code from phone number if it exists
        if (phoneNumber) {
          const match = phoneNumber.match(/^(\+\d+)/);
          if (match && match[1]) {
            countryCode = match[1];
            // Remove country code from phone number
            phoneNumber = phoneNumber.replace(countryCode, '');
          }
        }

        // Update form values
        setValue('firstName', updatedData.firstName || watch('firstName'));
        setValue('lastName', updatedData.lastName || watch('lastName'));
        setValue('phoneNumber', phoneNumber);
        setValue('countryCode', countryCode);
        setValue('email', updatedData.email || watch('email'));
        setValue(
          'dateOfBirth',
          updatedData.dateOfBirth || watch('dateOfBirth')
        );
        setValue('profilePictureId', null);

        showNotification('Profile picture removed successfully', 'success');
      } else {
        showNotification(
          'Failed to update profile after removing picture',
          'error'
        );
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
      showNotification('Failed to remove profile picture', 'error');
    } finally {
      setSaving(false);
    }
  };

  const getInitials = () => {
    if (!auth.user) return '';
    return `${auth.user.firstName?.charAt(0) || ''}${auth.user.lastName?.charAt(0) || ''}`.toUpperCase();
  };

  if (!auth.user) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" align="center">
          {i18next.t(
            'Views.ProfilePage.NotLoggedIn',
            'Please log in to view your profile'
          )}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Header
        title={i18next.t('Views.ProfilePage.Title', 'Profile')}
        subtitle={i18next.t(
          'Views.ProfilePage.Subtitle',
          'Manage your personal information'
        )}
      />
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Grid container spacing={4}>
          {/* Profile Summary Card - Full Width */}
          <Grid item xs={12}>
            <ProfileSummaryCard
              user={auth.user}
              profilePictureId={watch('profilePictureId') || null}
              firstName={watch('firstName')}
              lastName={watch('lastName')}
              onImageChange={handleImageChange}
              onRemoveProfilePicture={handleRemoveProfilePicture}
              getInitials={getInitials}
            />
          </Grid>

          {/* Personal Information - Full Width */}
          <Grid item xs={12}>
            <PersonalInformation
              form={{
                control,
                handleSubmit,
                watch,
                setValue,
                trigger,
                clearErrors,
                setError,
                formState: { errors },
              }}
              loading={loading}
              saving={saving}
              onSubmit={onSubmit}
              onVerifyPhone={handleVerifyPhone}
            />
          </Grid>

          {/* Notification Preferences - Full Width */}
          <Grid item xs={12}>
            <NotificationPreferences
              initialSettings={notificationSettings}
              onSettingsChange={handleNotificationSettingsChange}
              loading={savingNotifications}
              phoneVerified={watch('phoneNumberConfirmed')}
            />
          </Grid>

          {/* Security Settings - Full Width */}
          <Grid item xs={12}>
            <SecuritySettings />
          </Grid>
        </Grid>

        <Snackbar
          open={showMessage}
          autoHideDuration={6000}
          onClose={handleCloseMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseMessage}
            severity={messageType}
            sx={{
              width: '100%',
              ...(messageType === 'success' && {
                bgcolor: '#D4AF37',
                color: '#000',
                '& .MuiAlert-icon': {
                  color: '#000',
                },
              }),
            }}
          >
            {message}
          </Alert>
        </Snackbar>

        {/* Phone Verification Dialog */}
        <VerifyPhoneDialog
          open={verifyDialogOpen}
          onClose={handleCloseVerifyDialog}
          onSuccess={handleVerificationSuccess}
          phoneNumber={`${watch('countryCode')}${watch('phoneNumber')}`}
        />
      </Container>
    </>
  );
};

export default ProfilePage;
