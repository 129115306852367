import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { FlagIcon } from 'react-flag-kit';
import { countryCodes } from '../../utils/countryCodes';
import i18next from '../../utilis/i18next';
import { apiUrl } from '../../main.tsx';
import axiosInstance from '../../utilis/axios.ts';

interface PhoneNumberFieldProps {
  control: Control<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  clearErrors: UseFormClearErrors<any>;
  setError: UseFormSetError<any>;
  errors: any;
  checkPhoneExists?: (
    phoneNumber: string,
    countryCode: string
  ) => Promise<void>;
  phoneExists?: boolean;
  phoneCheckLoading?: boolean;
  setPhoneExists?: React.Dispatch<React.SetStateAction<boolean>>;
  helperTextConfirmed?: string;
  helperTextNotConfirmed?: string;
  phoneNumberConfirmed?: boolean;
  required?: boolean;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  control,
  watch,
  setValue,
  trigger,
  clearErrors,
  setError,
  errors,
  checkPhoneExists,
  phoneExists = false,
  phoneCheckLoading = false,
  setPhoneExists,
  helperTextConfirmed,
  helperTextNotConfirmed,
  phoneNumberConfirmed = false,
  required = false,
}) => {
  const [localPhoneExists, setLocalPhoneExists] = useState(phoneExists);
  const [isCheckingPhone, setIsCheckingPhone] = useState(phoneCheckLoading);

  // Update local state when prop changes
  useEffect(() => {
    setLocalPhoneExists(phoneExists);
  }, [phoneExists]);

  useEffect(() => {
    setIsCheckingPhone(phoneCheckLoading);
  }, [phoneCheckLoading]);

  // Default implementation to check if phone exists if not provided by parent
  const defaultCheckPhoneExists = useCallback(
    debounce(async (phoneNumber: string, countryCode: string) => {
      if (!phoneNumber || !countryCode) return;

      try {
        setIsCheckingPhone(true);
        const formattedPhone = `${countryCode}${phoneNumber}`;

        // Call the API to check if phone exists
        const response = await axiosInstance.get(
          `${apiUrl}/auth/check-phone-exists?phoneNumber=${encodeURIComponent(formattedPhone)}`
        );

        const exists = response.data?.exists || false;
        setLocalPhoneExists(exists);
        if (setPhoneExists) {
          setPhoneExists(exists);
        }

        if (exists) {
          setError('phoneNumber', {
            type: 'manual',
            message: i18next.t(
              'Common.PhoneAlreadyExists',
              'This phone number is already registered'
            ),
          });
        } else {
          clearErrors('phoneNumber');
        }
      } catch (error) {
        console.error('Error checking phone existence:', error);
      } finally {
        setIsCheckingPhone(false);
      }
    }, 500),
    [apiUrl, setError, clearErrors, setPhoneExists]
  );

  // Use provided check function or default implementation
  const checkPhoneExistsFunction = checkPhoneExists || defaultCheckPhoneExists;

  // Function to detect country code from phone number
  const detectCountryCode = (phoneNumber: string): string | null => {
    if (!phoneNumber || phoneNumber.length < 2) return null;

    // If phone number starts with +, try to match with country codes
    if (phoneNumber.startsWith('+')) {
      // Try to match the beginning of the phone number with country codes
      // Sort country codes by length (longest first) to handle overlapping prefixes
      const sortedCodes = [...countryCodes].sort(
        (a, b) => b.code.length - a.code.length
      );

      for (const country of sortedCodes) {
        if (phoneNumber.startsWith(country.code)) {
          return country.code;
        }
      }
    }
    // Try to detect without the + prefix
    else if (/^\d+/.test(phoneNumber)) {
      // Common international prefixes to check
      const internationalPrefixes = ['00', '011'];

      // Check if the number starts with an international prefix
      for (const prefix of internationalPrefixes) {
        if (phoneNumber.startsWith(prefix)) {
          // Remove the prefix and check for country code
          const numberWithoutPrefix = phoneNumber.substring(prefix.length);

          for (const country of countryCodes) {
            const codeWithoutPlus = country.code.substring(1); // Remove the "+"
            if (numberWithoutPrefix.startsWith(codeWithoutPlus)) {
              return country.code;
            }
          }
        }
      }

      // For numbers without international prefix that might start with country code
      for (const country of countryCodes) {
        const codeWithoutPlus = country.code.substring(1); // Remove the "+"
        if (phoneNumber.startsWith(codeWithoutPlus)) {
          // Check if it's a plausible country code match (avoid matching normal numbers)
          // Most country codes are 1-3 digits
          if (
            codeWithoutPlus.length <= 3 &&
            phoneNumber.length > codeWithoutPlus.length + 5
          ) {
            return country.code;
          }
        }
      }
    }

    return null;
  };

  // Function to handle phone number changes
  const handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: any
  ) => {
    const inputPhoneNumber = e.target.value;

    // Reset phone exists state on change
    if (localPhoneExists) {
      if (setPhoneExists) {
        setPhoneExists(false);
      }
      setLocalPhoneExists(false);
      clearErrors('phoneNumber');
    }

    // Try to detect country code only for new inputs that might contain a country code
    if (
      inputPhoneNumber &&
      (inputPhoneNumber.startsWith('+') || /^\d{2,}/.test(inputPhoneNumber))
    ) {
      const detectedCode = detectCountryCode(inputPhoneNumber);

      if (detectedCode) {
        setValue('countryCode', detectedCode);

        // Remove the country code from the phone number
        let phoneWithoutCode;
        if (inputPhoneNumber.startsWith('+')) {
          phoneWithoutCode = inputPhoneNumber.substring(detectedCode.length);
        } else if (inputPhoneNumber.startsWith('00')) {
          phoneWithoutCode = inputPhoneNumber.substring(
            2 + detectedCode.substring(1).length
          );
        } else {
          phoneWithoutCode = inputPhoneNumber.substring(
            detectedCode.substring(1).length
          );
        }

        // Update the phone field with the cleaned number
        field.onChange(phoneWithoutCode.trim());
        return;
      }
    }

    // If no country code detected, just update the field normally
    field.onChange(e);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: required
            ? i18next.t(
                'Common.PhoneNumberRequired',
                'Phone number is required'
              )
            : false,
          pattern: {
            value: /^[0-9\s]{8,15}$/,
            message: i18next.t(
              'Common.PhoneNumberInvalid',
              'Invalid phone number format'
            ),
          },
          validate: async (value) => {
            if (value && localPhoneExists) {
              return i18next.t(
                'Common.PhoneAlreadyExists',
                'This phone number is already registered'
              );
            }
            if (value) {
              const countryCode = watch('countryCode');
              if (!countryCode) {
                setError('countryCode', {
                  type: 'manual',
                  message: i18next.t(
                    'Common.CountryCodeRequired',
                    'Country code is required'
                  ),
                });
                return 'Please select a country code';
              }

              // Check if phone number exists
              await checkPhoneExistsFunction(value, countryCode);
              if (localPhoneExists) {
                return i18next.t(
                  'Common.PhoneAlreadyExists',
                  'This phone number is already registered'
                );
              }
            }
            return true;
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            onChange={(e) =>
              handlePhoneNumberChange(
                e as React.ChangeEvent<HTMLInputElement>,
                field
              )
            }
            margin="normal"
            fullWidth
            required={required}
            id="phoneNumber"
            label={i18next.t('Common.PhoneNumberLabel', 'Phone Number')}
            autoComplete="tel"
            error={!!errors.phoneNumber || localPhoneExists}
            helperText={
              errors.phoneNumber?.message ||
              (localPhoneExists
                ? i18next.t(
                    'Common.PhoneAlreadyExists',
                    'This phone number is already registered'
                  )
                : phoneNumberConfirmed
                  ? helperTextConfirmed ||
                    i18next.t(
                      'Common.PhoneVerified',
                      'Phone number is verified'
                    )
                  : helperTextNotConfirmed || '')
            }
            FormHelperTextProps={{
              sx: {
                color:
                  errors.phoneNumber || localPhoneExists
                    ? 'error.main'
                    : phoneNumberConfirmed
                      ? 'success.main'
                      : watch('phoneNumber')
                        ? 'warning.main'
                        : 'text.secondary',
                fontWeight:
                  phoneNumberConfirmed || watch('phoneNumber') ? 500 : 400,
              },
            }}
            onBlur={(e) => {
              field.onBlur();
              if (e.target.value && watch('countryCode')) {
                checkPhoneExistsFunction(e.target.value, watch('countryCode'));
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ pr: 0 }}>
                  <Controller
                    name="countryCode"
                    control={control}
                    defaultValue="+47"
                    rules={{
                      required: {
                        value: !!watch('phoneNumber'),
                        message: i18next.t(
                          'Common.CountryCodeRequired',
                          'Country code is required'
                        ),
                      },
                    }}
                    render={({ field: countryField }) => (
                      <Select
                        {...countryField}
                        displayEmpty
                        variant="standard"
                        disableUnderline
                        sx={{
                          width: 80,
                          border: 'none',
                          '& .MuiSelect-select': {
                            color:
                              errors.phoneNumber || localPhoneExists
                                ? 'error.main'
                                : 'inherit',
                            pr: 1,
                            pl: 0,
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '& .MuiSvgIcon-root': {
                            color: errors.countryCode
                              ? 'error.main'
                              : 'rgba(255, 255, 255, 0.7)',
                          },
                        }}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {selected && (
                              <Box
                                sx={{
                                  mr: 0.5,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <FlagIcon
                                  code={
                                    countryCodes.find(
                                      (c) => c.code === selected
                                    )?.country || 'NO'
                                  }
                                  size={16}
                                />
                              </Box>
                            )}
                            <Typography variant="body2">{selected}</Typography>
                          </Box>
                        )}
                      >
                        {countryCodes.map((option) => (
                          <MenuItem
                            key={option.code}
                            value={option.code}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Box
                              sx={{
                                mr: 1,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <FlagIcon code={option.country} size={16} />
                            </Box>
                            <Typography variant="body2">
                              {option.code}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontSize: '0.75rem',
                              }}
                            >
                              ({option.name})
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {(phoneCheckLoading || isCheckingPhone) && (
                    <CircularProgress size={20} />
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor:
                    errors.phoneNumber || localPhoneExists
                      ? 'error.main'
                      : 'rgba(212, 175, 55, 0.3)',
                },
                '&:hover fieldset': {
                  borderColor:
                    errors.phoneNumber || localPhoneExists
                      ? 'error.main'
                      : 'rgba(212, 175, 55, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor:
                    errors.phoneNumber || localPhoneExists
                      ? 'error.main'
                      : '#D4AF37',
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color:
                    errors.phoneNumber || localPhoneExists
                      ? 'error.main'
                      : '#D4AF37',
                },
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default PhoneNumberField;
