import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Slider,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axiosInstance from '../../../utilis/axios';
import { apiUrl } from '../../../main';
import { useSnackbar } from 'notistack';

interface AvatarUploadProps {
  initialImageId: string | null;
  onImageChange: (imageId: string | null) => void;
  initials: string;
  name: string;
}

// Hidden input for file selection
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Create an image from a URL
const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

// Simple approach that captures exactly what's shown in the UI
const getCroppedImg = async (
  imageSrc: string,
  position: { x: number, y: number },
  zoom: number,
  cropSize: number
): Promise<Blob> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  // Set canvas size to our desired output size
  const outputSize = 250; // Fixed output size
  canvas.width = outputSize;
  canvas.height = outputSize;

  // Create a circular clipping path
  ctx.beginPath();
  ctx.arc(outputSize / 2, outputSize / 2, outputSize / 2, 0, Math.PI * 2);
  ctx.clip();
  
  // Calculate how the image is positioned relative to the crop box
  // This is a direct equivalent to how CSS transforms work in the preview
  
  // First, calculate center-point coordinates for drawing the image 
  const centerX = outputSize / 2;
  const centerY = outputSize / 2;
  
  // Calculate the scaled image size
  const scaledWidth = image.width * zoom;
  const scaledHeight = image.height * zoom;
  
  // Position the image exactly as it appears in the preview
  // position.x and position.y represent the top-left point of the image relative to the crop area
  console.log('Crop parameters:', {
    imageSize: { width: image.width, height: image.height },
    position,
    zoom,
    scaledSize: { width: scaledWidth, height: scaledHeight },
    cropSize
  });
  
  // Draw the image exactly as it's shown in the UI
  ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    centerX - (scaledWidth / 2) + position.x,
    centerY - (scaledHeight / 2) + position.y,
    scaledWidth,
    scaledHeight
  );

  // Convert to blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas is empty'));
        }
      },
      'image/png',
      0.95
    );
  });
};

const AvatarUpload: React.FC<AvatarUploadProps> = ({
  initialImageId,
  onImageChange,
  initials,
  name,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(() => {
    const url = initialImageId ? `${apiUrl}/files/${initialImageId}` : null;
    console.log('Initial image ID:', initialImageId);
    console.log('Setting initial preview URL:', url);
    return url;
  });
  
  // Update preview URL when initialImageId changes
  useEffect(() => {
    console.log('initialImageId changed:', initialImageId);
    if (initialImageId) {
      const url = `${apiUrl}/files/${initialImageId}`;
      console.log('Setting new preview URL:', url);
      setPreviewUrl(url);
    } else {
      console.log('Clearing preview URL');
      setPreviewUrl(null);
    }
  }, [initialImageId]);

  // Crop state
  const [zoom, setZoom] = useState(1.5);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [cropSize, setCropSize] = useState(250); // Default crop size

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      
      reader.onload = (e) => {
        if (e.target?.result) {
          setImageSrc(e.target.result as string);
          
          // Reset crop position and zoom
          setZoom(1.5);
          setPosition({ x: 0, y: 0 });
          
          // Load image to get dimensions
          const img = new Image();
          img.onload = () => {
            setImageSize({ width: img.width, height: img.height });
          };
          img.src = e.target.result as string;
          
          setIsDialogOpen(true);
        }
      };
      
      reader.readAsDataURL(file);
    }
    
    // Reset the input value so the same file can be selected again
    if (event.target) {
      event.target.value = '';
    }
  };

  // Handle mouse down for dragging
  const handleMouseDown = (e: React.MouseEvent) => {
    setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  // Handle mouse move for dragging
  const handleMouseMove = (e: React.MouseEvent) => {
    if (dragStart) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      });
    }
  };

  // Handle mouse up to end dragging
  const handleMouseUp = () => {
    setDragStart(null);
  };

  // Handle touch start for mobile dragging
  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setDragStart({ x: touch.clientX - position.x, y: touch.clientY - position.y });
  };

  // Handle touch move for mobile dragging
  const handleTouchMove = (e: React.TouchEvent) => {
    if (dragStart) {
      const touch = e.touches[0];
      setPosition({
        x: touch.clientX - dragStart.x,
        y: touch.clientY - dragStart.y,
      });
    }
  };

  // Handle touch end to end mobile dragging
  const handleTouchEnd = () => {
    setDragStart(null);
  };

  // Save the cropped image
  const handleSave = async () => {
    try {
      if (!imageSrc) return;

      // Simply pass the current position, zoom and crop size
      // This directly captures what the user is seeing in the preview
      console.log('Crop calculations:', { 
        position,
        zoom,
        cropSize
      });

      // Get the cropped image using the current display parameters
      const croppedImage = await getCroppedImg(
        imageSrc,
        position,
        zoom,
        cropSize
      );

      // Create a temporary preview URL
      const objectUrl = URL.createObjectURL(croppedImage);
      setPreviewUrl(objectUrl);

      // Close dialog and clear source image
      setIsDialogOpen(false);
      setImageSrc(null);

      // Upload the cropped image
      const formData = new FormData();
      formData.append('data', croppedImage, 'avatar.png');

      try {
        let response;
        if (initialImageId) {
          // Update existing file
          response = await axiosInstance.put(
            `${apiUrl}/files/${initialImageId}`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
        } else {
          // Create new file
          response = await axiosInstance.post(
            `${apiUrl}/files`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
        }

        const imageId = response.data.id;
        setPreviewUrl(`${apiUrl}/files/${imageId}`);
        onImageChange(imageId);

        enqueueSnackbar('Profile picture updated successfully!', {
          variant: 'success',
        });

        // Clean up the temporary object URL
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
        enqueueSnackbar('Failed to upload profile picture', {
          variant: 'error',
        });

        // Clean up
        URL.revokeObjectURL(objectUrl);
        setPreviewUrl(null);
      }
    } catch (error) {
      console.error('Error cropping image:', error);
      setIsDialogOpen(false);
      setImageSrc(null);
      enqueueSnackbar('Failed to crop image', {
        variant: 'error',
      });
    }
  };



  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-block' }}>
        {/* Avatar with profile picture or initials */}
        <Avatar
          src={previewUrl || undefined}
          alt={name}
          sx={{
            width: 120,
            height: 120,
            fontSize: 48,
            bgcolor: '#D4AF37',
            color: '#000',
            border: '4px solid',
            borderColor: 'rgba(212, 175, 55, 0.3)',
            boxShadow: '0 4px 20px rgba(212, 175, 55, 0.25)'
          }}
        >
          {!previewUrl && initials}
        </Avatar>
        
        {/* Camera icon button for changing profile picture */}
        <IconButton
          aria-label="change profile picture"
          onClick={() => fileInputRef.current?.click()}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(212, 175, 55, 0.8)',
            color: '#000',
            '&:hover': {
              backgroundColor: '#D4AF37',
            },
            width: 36,
            height: 36,
            border: '2px solid #000',
          }}
        >
          <AddAPhotoIcon fontSize="small" />
        </IconButton>
        
        {/* Hidden file input */}
        <VisuallyHiddenInput
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={handleFileChange}
        />
      </Box>
      

      
      {/* Crop dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>Crop Profile Picture</DialogTitle>
        <DialogContent dividers>
          {imageSrc && (
            <>
              <Box
                data-testid="crop-container"
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 350,
                  overflow: 'hidden',
                  backgroundColor: '#000',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2,
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                {/* Circular crop overlay */}
                <Box
                  className="crop-overlay"
                  sx={{
                    position: 'absolute',
                    width: cropSize,
                    height: cropSize,
                    borderRadius: '50%',
                    border: '2px dashed white',
                    zIndex: 2,
                    pointerEvents: 'none',
                  }}
                />
                
                {/* Semi-transparent overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                    mask: `radial-gradient(circle ${cropSize / 2}px at center, transparent 100%, black 100%)`,
                    WebkitMask: `radial-gradient(circle ${cropSize / 2}px at center, transparent 100%, black 100%)`,
                    pointerEvents: 'none',
                  }}
                />
                
                {/* Image to crop */}
                <Box
                  component="img"
                  src={imageSrc}
                  alt="Crop preview"
                  sx={{
                    position: 'relative',
                    transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,
                    transformOrigin: 'center',
                    maxWidth: 'none',
                    userSelect: 'none',
                    touchAction: 'none',
                  }}
                  draggable={false}
                />
              </Box>
              
              {/* Zoom control */}
              <Box sx={{ px: 2, py: 1 }}>
                <Typography gutterBottom>Zoom</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <ZoomOutIcon sx={{ mr: 1 }} />
                  {/* Increased minimum zoom to prevent cropping issues */}
                  <Slider
                    value={zoom}
                    min={0.5}
                    max={3}
                    step={0.01}
                    onChange={(_, value) => setZoom(value as number)}
                    sx={{ mx: 2 }}
                  />
                  <ZoomInIcon sx={{ ml: 1 }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setZoom(prev => Math.max(0.5, prev - 0.05))}
                    sx={{ minWidth: '36px', mr: 1 }}
                  >
                    -
                  </Button>
                  <Typography variant="body2" sx={{ mx: 1 }}>
                    {zoom.toFixed(2)}x
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setZoom(prev => Math.min(3, prev + 0.05))}
                    sx={{ minWidth: '36px', ml: 1 }}
                  >
                    +
                  </Button>
                </Box>
                <Typography variant="caption" color="text.secondary">
                  Drag to position • Zoom in/out to adjust size
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AvatarUpload;
