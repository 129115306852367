import React, { useEffect, useState } from 'react';
import {
  Alert as MuiAlert,
  AlertProps,
  Box,
  Button,
  Container,
  Snackbar,
  Typography,
} from '@mui/material';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import i18next from '../../utilis/i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import RegistrationForm from './components/RegistrationForm';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { resendConfirmationEmail } from '../../store/slices/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

// Configurable constants
const MAX_RESEND_ATTEMPTS = 5;
const RESEND_COUNTDOWN_SECONDS = 60;
const RESEND_SUCCESS_MESSAGE_DURATION = 3000; // milliseconds

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RegisterPage = () => {
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [canResend, setCanResend] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(
    RESEND_COUNTDOWN_SECONDS
  );
  const [resendAttempts, setResendAttempts] = useState(0);
  const [showResendSuccess, setShowResendSuccess] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  useDocumentTitle(i18next.t('Views.RegisterPage.Title'));

  // Handle resend email functionality
  const handleResendEmail = async () => {
    if (resendAttempts >= MAX_RESEND_ATTEMPTS || !canResend || isResending) {
      return;
    }

    setIsResending(true);

    try {
      // Call the backend API to resend the confirmation email
      const resultAction = await dispatch(
        resendConfirmationEmail(registeredEmail)
      );
      const result = unwrapResult(resultAction);

      if (result.success) {
        setResendAttempts((prev) => prev + 1);
        setShowResendSuccess(true);

        // Reset countdown
        setCanResend(false);
        setResendCountdown(RESEND_COUNTDOWN_SECONDS);

        // Hide resend success message after specified duration
        setTimeout(() => {
          setShowResendSuccess(false);
        }, RESEND_SUCCESS_MESSAGE_DURATION);
      } else {
        handleRegistrationError(
          i18next.t('Views.RegisterPage.EmailSendingError')
        );
      }
    } catch (error) {
      console.error('Failed to resend confirmation email:', error);
      handleRegistrationError(
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : i18next.t('Views.RegisterPage.EmailSendingError')
      );
    } finally {
      setIsResending(false);
    }
  };

  // Countdown timer for resend button
  useEffect(() => {
    let timer: number | undefined;

    if (!canResend && resendCountdown > 0) {
      timer = setTimeout(() => {
        setResendCountdown((prev) => prev - 1);
      }, 1000);
    } else if (resendCountdown === 0) {
      setCanResend(true);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [canResend, resendCountdown]);

  const handleSuccessfulRegistration = (email: string) => {
    console.log('Registration successful, email:', email);
    setRegisteredEmail(email);

    // Start countdown for resend option
    setCanResend(false);
    setResendCountdown(RESEND_COUNTDOWN_SECONDS);

    // Force immediate UI update to show success screen
    setTimeout(() => {
      setRegistrationComplete(true);
    }, 0);
  };

  const handleRegistrationError = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const handleCloseError = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  console.log('Registration state:', { registrationComplete });

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      {registrationComplete ? (
        <Box
          sx={{
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 1000,
            py: 8,
            overflow: 'auto',
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 80,
              color: '#D4AF37',
              mb: 3,
              animation: 'fadeIn 0.5s ease-in',
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'scale(0.8)' },
                to: { opacity: 1, transform: 'scale(1)' },
              },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              color: '#D4AF37',
              mb: 2,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.2s',
              animationFillMode: 'both',
            }}
          >
            {i18next.t('Views.RegisterPage.RegistrationSuccess')}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              mb: 2,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.3s',
              animationFillMode: 'both',
            }}
          >
            {i18next.t('Views.RegisterPage.CheckEmail')}
          </Typography>
          <Typography
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.4s',
              animationFillMode: 'both',
              maxWidth: '500px',
              px: 2,
            }}
          >
            {i18next.t('Views.RegisterPage.CheckEmailDetails', {
              email: registeredEmail,
            })}
          </Typography>

          {/* Resend email section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              animation: 'fadeIn 0.5s ease-in 0.5s',
              animationFillMode: 'both',
            }}
          >
            {showResendSuccess && (
              <Alert
                severity="success"
                sx={{
                  mb: 2,
                  backgroundColor: 'rgba(46, 125, 50, 0.9)',
                  color: '#fff',
                }}
              >
                {i18next.t('Views.RegisterPage.EmailResent')}
              </Alert>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <EmailIcon sx={{ mr: 1, color: 'rgba(255, 255, 255, 0.7)' }} />
              <Typography
                sx={{
                  color: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                {i18next.t('Views.RegisterPage.NotReceiveEmail')}
              </Typography>
            </Box>

            <Button
              variant="outlined"
              disabled={
                !canResend ||
                resendCountdown > 0 ||
                resendAttempts >= MAX_RESEND_ATTEMPTS ||
                isResending
              }
              onClick={handleResendEmail}
              sx={{
                color: '#D4AF37',
                borderColor: '#D4AF37',
                '&:hover': {
                  borderColor: '#FFD700',
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                },
                '&.Mui-disabled': {
                  borderColor: 'rgba(212, 175, 55, 0.3)',
                  color: 'rgba(212, 175, 55, 0.3)',
                },
              }}
            >
              {isResending
                ? i18next.t('Views.RegisterPage.ResendingEmail')
                : resendAttempts >= MAX_RESEND_ATTEMPTS
                  ? i18next.t('Views.RegisterPage.MaxRetriesReached')
                  : resendCountdown > 0
                    ? `${i18next.t('Views.RegisterPage.ResendIn')} ${resendCountdown}s`
                    : i18next.t('Views.RegisterPage.ResendEmail')}
            </Button>

            {resendAttempts > 0 && resendAttempts < MAX_RESEND_ATTEMPTS && (
              <Typography
                variant="caption"
                sx={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  mt: 1,
                  fontSize: '0.7rem',
                }}
              >
                {i18next.t('Views.RegisterPage.RemainingRetries', {
                  count: MAX_RESEND_ATTEMPTS - resendAttempts,
                })}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Container
          component="main"
          maxWidth="md"
          sx={{
            pt: { xs: 4, sm: 8 },
            pb: 4,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Box
            mt={10}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              animation: 'fadeIn 0.8s ease-out',
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'translateY(20px)' },
                to: { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{
                mb: 4,
                fontWeight: 600,
                textAlign: 'center',
                background:
                  'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundSize: '200% auto',
                animation: 'shine 3s linear infinite, slideDown 0.8s ease-out',
                '@keyframes shine': {
                  to: {
                    backgroundPosition: '200% center',
                  },
                },
                '@keyframes slideDown': {
                  from: {
                    opacity: 0,
                    transform: 'translateY(-20px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'translateY(0)',
                  },
                },
              }}
            >
              {i18next.t('Views.RegisterPage.Title')}
            </Typography>

            <Box
              sx={{
                width: '100%',
                padding: 0,
                position: 'relative',
                overflow: 'hidden',
                background: 'transparent',
                animation: 'formAppear 0.8s ease-out 0.2s both',
                '@keyframes formAppear': {
                  from: {
                    opacity: 0,
                    transform: 'scale(0.95)',
                    filter: 'blur(5px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'scale(1)',
                    filter: 'blur(0)',
                  },
                },
              }}
            >
              <RegistrationForm
                onSubmitSuccess={(email) => handleSuccessfulRegistration(email)}
                onError={handleRegistrationError}
              />
            </Box>

            <Snackbar
              open={showError}
              autoHideDuration={6000}
              onClose={handleCloseError}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Alert
                onClose={handleCloseError}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'rgba(211, 47, 47, 0.95)',
                  color: '#fff',
                  backdropFilter: 'blur(10px)',
                }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default RegisterPage;
