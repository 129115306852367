import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState, selectAuth } from '../../store/slices/authSlice.ts';
import { AppDispatch } from '../../store/store.ts';
import i18next from '../../utilis/i18next.ts';
import { useNavigate } from 'react-router-dom';

export interface MenuItemLink {
  link: string;
  text: string;
  icon?: string;
}

export interface MenuItemsProps {
  items: MenuItemLink[];
  handleClose?: () => void;
}

const goldGradientStyle = {
  background:
    'linear-gradient(to right, #D4AF37 0%, #FFD700 50%, #D4AF37 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  transform: 'translateY(-2px)',
};

const buttonBaseStyle = {
  color: 'rgba(255, 255, 255, 0.9)',
  fontWeight: 500,
  transition: 'all 0.3s ease',
  position: 'relative',
  '&:hover': {
    ...goldGradientStyle,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background:
        'linear-gradient(to right, #D4AF37 0%, #FFD700 50%, #D4AF37 100%)',
      opacity: 0.7,
    },
  },
};

export const NavItemsLeft: React.FC<MenuItemsProps> = ({ items }) => (
  <Stack direction="row" spacing={2}>
    {items.map((item) => (
      <Button
        href={i18next.t(item.link)}
        key={item.link}
        sx={{
          ...buttonBaseStyle,
          py: 2,
        }}
      >
        {i18next.t(item.text)}
      </Button>
    ))}
  </Stack>
);

export const NavItemsRight: React.FC<MenuItemsProps> = ({ items }) => (
  <Stack direction="row" spacing={2}>
    {items.map((item) => (
      <Button
        variant="outlined"
        href={i18next.t(item.link)}
        key={item.link}
        sx={{
          ...buttonBaseStyle,
          borderColor: 'rgba(212, 175, 55, 0.3)',
          borderWidth: '2px',
          py: 1,
          px: 3,
          '&:hover': {
            ...goldGradientStyle,
            borderColor: '#D4AF37',
            backgroundColor: 'rgba(212, 175, 55, 0.05)',
          },
        }}
      >
        {i18next.t(item.text)}
      </Button>
    ))}
  </Stack>
);

export const MenuItems: React.FC<MenuItemsProps> = ({ items, handleClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);

  const handleLogout = () => {
    dispatch(clearAuthState());
    navigate('/');
    if (handleClose) handleClose();
  };

  const menuItemStyle = {
    color: 'rgba(255, 255, 255, 0.9)',
    transition: 'all 0.3s ease',
    py: 1.5,
    px: 2,
    '&:hover': {
      backgroundColor: 'rgba(212, 175, 55, 0.1)',
      ...goldGradientStyle,
    },
  };

  return (
    <Box
      sx={{
        bgcolor: 'transparent',
        py: 1,
        width: '100%',
      }}
    >
      {!auth?.user &&
        items.map((item) => (
          <MenuItem
            key={item.link}
            onClick={() => {
              if (handleClose) handleClose();
              navigate(i18next.t(item.link));
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">{i18next.t(item.text)}</Typography>
          </MenuItem>
        ))}

      {auth?.user && (
        <>
          {/*Profile*/}
          <MenuItem
            onClick={() => {
              if (handleClose) handleClose();
              navigate('/profile');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">
              {i18next.t('Components.RenderMenuNav.Profile')}
            </Typography>
          </MenuItem>

          {/*Messages*/}
          <MenuItem
            onClick={() => {
              if (handleClose) handleClose();
              navigate('/messages');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">Messages</Typography>
          </MenuItem>

          {/*Event Planner*/}
          <MenuItem
            onClick={() => {
              if (handleClose) handleClose();
              navigate('/planner');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">Event Planner</Typography>
          </MenuItem>

          {/* Company Section Divider */}
          <Box
            sx={{
              my: 1,
              borderBottom: '1px solid rgba(212, 175, 55, 0.3)',
              mx: 2,
            }}
          />

          {/*Companies*/}
          <MenuItem
            onClick={() => {
              if (handleClose) handleClose();
              navigate('/companies');
            }}
            sx={menuItemStyle}
          >
            <Typography textAlign="center">
              {i18next.t('Components.RenderMenuNav.Companies')}
            </Typography>
          </MenuItem>

          {/* Logout Section Divider */}
          <Box
            sx={{
              my: 1,
              borderBottom: '1px solid rgba(212, 175, 55, 0.3)',
              mx: 2,
            }}
          />

          {/*Logout*/}
          <MenuItem onClick={handleLogout} sx={menuItemStyle}>
            <Typography textAlign="center">
              {i18next.t('Components.RenderMenuNav.Logout')}
            </Typography>
          </MenuItem>

          {/*Admin Dashboard*/}
          {auth.user?.roles && auth.user.roles.includes('Admin') && (
            <MenuItem
              onClick={() => {
                if (handleClose) handleClose();
                navigate('/admin');
              }}
              sx={menuItemStyle}
            >
              <Typography textAlign="center">Admin Dashboard</Typography>
            </MenuItem>
          )}
        </>
      )}
    </Box>
  );
};

export default MenuItems;
