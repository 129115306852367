import React from 'react';
import { Button } from '@mui/material';
import i18next from '../../../utilis/i18next';
import Section from '../../../components/common/Section.tsx';
import SectionTitle from '../../../components/common/SectionTitle.tsx';

const SecuritySettings: React.FC = () => {
  return (
    <Section>
      <SectionTitle
        title={i18next.t('Views.ProfilePage.SecuritySection', 'Security')}
      />
      <Button
        variant="outlined"
        fullWidth
        href="/forgot-password"
        sx={{
          mt: 2,
          borderColor: 'rgba(212, 175, 55, 0.5)',
          color: '#D4AF37',
          '&:hover': {
            borderColor: '#D4AF37',
            backgroundColor: 'rgba(212, 175, 55, 0.05)',
          },
        }}
      >
        {i18next.t('Views.ProfilePage.ChangePassword', 'Change Password')}
      </Button>
    </Section>
  );
};

export default SecuritySettings;
