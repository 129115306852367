import React, { useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  Select,
  MenuItem,
  Grid,
  Paper,
  Collapse,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import i18next from '../../../utilis/i18next';
import Section from '../../../components/common/Section.tsx';
import SectionTitle from '../../../components/common/SectionTitle.tsx';

// Import types from centralized location
import {
  NotificationSettings,
  Channel,
  NotificationType,
  FrequencyOption,
} from '../../../types/NotificationTypes';

interface NotificationPreferencesProps {
  /** Initial notification settings */
  initialSettings?: Partial<NotificationSettings>;
  /** Called when settings are changed */
  onSettingsChange?: (settings: NotificationSettings) => void;
  /** Whether notification settings are being saved/loaded */
  loading?: boolean;
  /** Whether the component is disabled */
  disabled?: boolean;
  /** Whether the user's phone number is verified */
  phoneVerified?: boolean;
}

const NotificationPreferences: React.FC<NotificationPreferencesProps> = ({
  initialSettings,
  onSettingsChange,
  loading = false,
  disabled = false,
  phoneVerified = false,
}) => {
  const theme = useTheme();

  // State for notification preferences
  const [channels, setChannels] =
    useState<NotificationSettings>(initialSettings);

  // Debug channel state
  React.useEffect(() => {
    console.log('Current channels state:', channels);
  }, [channels]);

  // Flag to track if this is the initial render
  const isInitialRender = React.useRef(true);
  const prevSettings = React.useRef<NotificationSettings>(initialSettings);

  // Effect to notify parent of changes - only after user interactions, not on initial render
  React.useEffect(() => {
    // Skip the notification on the first render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      prevSettings.current = channels;
      return;
    }

    // Don't update unless there's an actual change
    if (JSON.stringify(prevSettings.current) !== JSON.stringify(channels)) {
      console.log('NotificationPreferences changed, calling onSettingsChange');

      // Convert frequency values to numbers for backend
      const convertedChannels = JSON.parse(JSON.stringify(channels));

      if (convertedChannels.email?.notificationTypes) {
        Object.keys(convertedChannels.email.notificationTypes).forEach(
          (key) => {
            if (
              convertedChannels.email.notificationTypes[key]?.frequency !==
              undefined
            ) {
              convertedChannels.email.notificationTypes[key].frequency =
                getFrequencyNumber(
                  convertedChannels.email.notificationTypes[key].frequency
                );
            }
          }
        );
      }

      if (convertedChannels.whatsApp?.notificationTypes) {
        Object.keys(convertedChannels.whatsApp.notificationTypes).forEach(
          (key) => {
            if (
              convertedChannels.whatsApp.notificationTypes[key]?.frequency !==
              undefined
            ) {
              convertedChannels.whatsApp.notificationTypes[key].frequency =
                getFrequencyNumber(
                  convertedChannels.whatsApp.notificationTypes[key].frequency
                );
            }
          }
        );
      }

      console.log('Sending converted channels to backend:', convertedChannels);
      if (onSettingsChange) {
        onSettingsChange(convertedChannels);
      }
      prevSettings.current = channels;
    }
  }, [channels, onSettingsChange]);

  // Toggle channel enabled state
  const handleChannelToggle = (channelKey: 'email' | 'whatsApp') => {
    if (disabled || loading) return;

    setChannels((prev) => {
      // Create default channel settings if not present
      if (!prev || !prev[channelKey]) {
        const newChannels = { ...prev } || {};
        newChannels[channelKey] = {
          name: channelKey === 'email' ? 'Email' : 'WhatsApp',
          enabled: true, // Enable it since we're toggling from undefined/false
          expanded: true,
          notificationTypes: {},
        };
        return newChannels;
      }

      // Normal toggle if the channel exists
      const updated = {
        ...prev,
        [channelKey]: {
          ...prev[channelKey],
          enabled: !prev[channelKey]?.enabled,
          expanded: !prev[channelKey]?.enabled, // Auto-expand when enabled
        },
      };
      return updated;
    });
  };

  // Toggle channel expanded state
  const handleExpandToggle = (channelKey: 'email' | 'whatsApp') => {
    if (disabled || loading) return;

    setChannels((prev) => {
      // Create default channel settings if not present
      if (!prev || !prev[channelKey]) {
        const newChannels = { ...prev } || {};
        newChannels[channelKey] = {
          name: channelKey === 'email' ? 'Email' : 'WhatsApp',
          enabled: true,
          expanded: true, // Expand it since we're toggling from undefined
          notificationTypes: {},
        };
        return newChannels;
      }

      // Normal toggle if the channel exists
      const updated = {
        ...prev,
        [channelKey]: {
          ...prev[channelKey],
          expanded: !prev[channelKey]?.expanded,
        },
      };
      return updated;
    });
  };

  // Convert string frequency to numeric for backend
  const getFrequencyNumber = (frequency: FrequencyOption | number): number => {
    if (typeof frequency === 'number') return frequency;

    // Convert string values to numeric values
    switch (frequency) {
      case 'instant':
        return 0;
      case 'endOfDay':
        return 1;
      case 'weekly':
        return 2;
      case 'disabled':
        return 3;
      default:
        return 3;
    }
  };

  // Update frequency for a notification type
  const handleFrequencyChange = (
    channelKey: 'email' | 'whatsApp',
    notificationKey: 'messages' | 'events' | 'promotions',
    value: FrequencyOption
  ) => {
    if (disabled || loading) return;

    setChannels((prev) => {
      // Create default structure if missing
      if (!prev) {
        return {
          [channelKey]: {
            name: channelKey === 'email' ? 'Email' : 'WhatsApp',
            enabled: true,
            expanded: true,
            notificationTypes: {
              [notificationKey]: {
                name:
                  notificationKey.charAt(0).toUpperCase() +
                  notificationKey.slice(1),
                frequency: value,
              },
            },
          },
        };
      }

      if (!prev[channelKey]) {
        return {
          ...prev,
          [channelKey]: {
            name: channelKey === 'email' ? 'Email' : 'WhatsApp',
            enabled: true,
            expanded: true,
            notificationTypes: {
              [notificationKey]: {
                name:
                  notificationKey.charAt(0).toUpperCase() +
                  notificationKey.slice(1),
                frequency: value,
              },
            },
          },
        };
      }

      if (!prev[channelKey].notificationTypes) {
        return {
          ...prev,
          [channelKey]: {
            ...prev[channelKey],
            notificationTypes: {
              [notificationKey]: {
                name:
                  notificationKey.charAt(0).toUpperCase() +
                  notificationKey.slice(1),
                frequency: value,
              },
            },
          },
        };
      }

      // Normal update if structure exists
      const updated = {
        ...prev,
        [channelKey]: {
          ...prev[channelKey],
          notificationTypes: {
            ...prev[channelKey].notificationTypes,
            [notificationKey]: {
              ...(prev[channelKey].notificationTypes[notificationKey] || {
                name:
                  notificationKey.charAt(0).toUpperCase() +
                  notificationKey.slice(1),
              }),
              frequency: value, // Store as string in UI state
            },
          },
        },
      };
      return updated;
    });
  };

  // Convert numeric frequency to string
  const getFrequencyString = (frequency: number | string): FrequencyOption => {
    if (typeof frequency === 'string') return frequency as FrequencyOption;

    // Convert numeric values to string values
    switch (frequency) {
      case 0:
        return 'instant';
      case 1:
        return 'endOfDay';
      case 2:
        return 'weekly';
      case 3:
        return 'disabled';
      default:
        return 'disabled';
    }
  };

  // Get text label for frequency options
  const getFrequencyLabel = (frequency: FrequencyOption | number): string => {
    const frequencyStr =
      typeof frequency === 'number' ? getFrequencyString(frequency) : frequency;

    switch (frequencyStr) {
      case 'instant':
        return i18next.t('Instant', 'Instant');
      case 'endOfDay':
        return i18next.t('End of Day Digest', 'End of Day Digest');
      case 'weekly':
        return i18next.t('Weekly Summary', 'Weekly Summary');
      case 'disabled':
        return i18next.t('Disabled', 'Disabled');
      default:
        return '';
    }
  };

  // Render a channel section
  const renderChannel = (channelKey: 'email' | 'whatsApp') => {
    const channel = channels?.[channelKey] || {
      name: channelKey === 'email' ? 'Email' : 'WhatsApp',
      enabled: false,
      expanded: false,
      notificationTypes: {},
    };

    // Make sure notificationTypes is always initialized to prevent crashes
    if (!channel.notificationTypes) {
      channel.notificationTypes = {};
    }
    console.log(
      `Rendering ${channelKey} channel with enabled:`,
      channel.enabled
    );
    const isWhatsAppDisabled = channelKey === 'whatsApp' && !phoneVerified;
    const icon =
      channelKey === 'email' ? (
        <EmailIcon sx={{ mr: 1, color: '#D4AF37' }} />
      ) : (
        <WhatsAppIcon
          sx={{ mr: 1, color: isWhatsAppDisabled ? '#9e9e9e' : '#25D366' }}
        />
      );

    return (
      <Box sx={{ mb: 3 }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            backgroundColor:
              channelKey === 'whatsApp' && !phoneVerified
                ? 'rgba(0, 0, 0, 0.05)'
                : 'rgba(212, 175, 55, 0.05)',
            borderRadius: 2,
            border: '1px solid',
            borderColor:
              channelKey === 'whatsApp' && !phoneVerified
                ? 'rgba(0, 0, 0, 0.1)'
                : 'rgba(212, 175, 55, 0.2)',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {icon}
              <Typography variant="h6" color="text.primary">
                {channel.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label={i18next.t(
                  channelKey === 'whatsApp' && !phoneVerified
                    ? 'Verify Phone to Enable WhatsApp Notifications'
                    : 'Enable Notifications',
                  channelKey === 'whatsApp' && !phoneVerified
                    ? 'Verify Phone to Enable WhatsApp Notifications'
                    : 'Enable Notifications'
                )}
                control={
                  <Switch
                    checked={channel.enabled}
                    onChange={() => handleChannelToggle(channelKey)}
                    disabled={
                      disabled ||
                      loading ||
                      (channelKey === 'whatsApp' && !phoneVerified)
                    }
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#D4AF37',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: 'rgba(212, 175, 55, 0.5)',
                        },
                    }}
                  />
                }
              />
              <Box
                onClick={() =>
                  channelKey === 'whatsApp' && !phoneVerified
                    ? null
                    : handleExpandToggle(channelKey)
                }
                sx={{
                  display: 'flex',
                  ml: 1,
                  cursor:
                    channelKey === 'whatsApp' && !phoneVerified
                      ? 'not-allowed'
                      : 'pointer',
                  opacity:
                    channel.enabled &&
                    !(channelKey === 'whatsApp' && !phoneVerified)
                      ? 1
                      : 0.5,
                }}
              >
                {channel.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            </Box>
          </Box>

          {channelKey === 'whatsapp' && !phoneVerified && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(245, 245, 245, 0.9)',
                borderRadius: 1,
                border: '1px dashed',
                borderColor: 'rgba(0, 0, 0, 0.2)',
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {i18next.t(
                  'Views.ProfilePage.VerifyPhoneForWhatsApp',
                  'Please verify your phone number to enable WhatsApp notifications'
                )}
              </Typography>
            </Box>
          )}

          <Collapse
            in={
              channel.enabled &&
              channel.expanded &&
              !(channelKey === 'whatsApp' && !phoneVerified)
            }
          >
            <Box sx={{ mt: 2 }}>
              <Divider sx={{ mb: 2 }} />
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{ backgroundColor: 'transparent' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          color: 'text.secondary',
                          borderBottom: '1px solid rgba(212, 175, 55, 0.2)',
                        }}
                      >
                        {i18next.t('Notification Type', 'Notification Type')}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          color: 'text.secondary',
                          borderBottom: '1px solid rgba(212, 175, 55, 0.2)',
                        }}
                      >
                        {i18next.t('Frequency', 'Frequency')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(channel.notificationTypes || {}).map(
                      ([key, notification]) =>
                        notification && (
                          <TableRow
                            key={key}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{
                                borderBottom:
                                  '1px solid rgba(212, 175, 55, 0.1)',
                              }}
                            >
                              {notification?.name || key}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom:
                                  '1px solid rgba(212, 175, 55, 0.1)',
                              }}
                            >
                              <FormControl size="small" fullWidth>
                                <Select
                                  value={getFrequencyString(
                                    notification?.frequency ?? 3
                                  )}
                                  onChange={(e) =>
                                    handleFrequencyChange(
                                      channelKey,
                                      key as
                                        | 'messages'
                                        | 'events'
                                        | 'promotions',
                                      e.target.value as FrequencyOption
                                    )
                                  }
                                  disabled={
                                    disabled || loading || !channel.enabled
                                  }
                                  sx={{
                                    '.MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'rgba(212, 175, 55, 0.3)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline':
                                      {
                                        borderColor: 'rgba(212, 175, 55, 0.5)',
                                      },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                      {
                                        borderColor: '#D4AF37',
                                      },
                                  }}
                                >
                                  <MenuItem value="instant">
                                    {getFrequencyLabel('instant')}
                                  </MenuItem>
                                  <MenuItem value="endOfDay">
                                    {getFrequencyLabel('endOfDay')}
                                  </MenuItem>
                                  <MenuItem value="weekly">
                                    {getFrequencyLabel('weekly')}
                                  </MenuItem>
                                  <MenuItem value="disabled">
                                    {getFrequencyLabel('disabled')}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </Paper>
      </Box>
    );
  };

  return (
    <Section sx={{ opacity: disabled ? 0.7 : 1, position: 'relative' }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>Loading...</Typography>
        </Box>
      )}
      <SectionTitle
        title={i18next.t(
          'Views.ProfilePage.NotificationPreferences',
          'Notification Preferences'
        )}
      />
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {i18next.t(
            'Views.ProfilePage.NotificationPreferencesDescription',
            'Choose how and when you want to receive notifications from Festportalen'
          )}
        </Typography>
      </Box>

      {renderChannel('email')}
      {renderChannel('whatsApp')}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontStyle: 'italic' }}
        >
          {i18next.t(
            'Views.ProfilePage.NotificationPreferencesSaveNote',
            'Your preferences are automatically saved when you make changes'
          )}
        </Typography>
      </Box>
    </Section>
  );
};

export default NotificationPreferences;
