import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import i18next from '../../../utilis/i18next';
import {
  checkEmailExists,
  register as registerUser,
} from '../../../store/slices/authSlice';
import { AppDispatch } from '../../../store/store';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/sv'; // Import Swedish locale
import 'dayjs/locale/en'; // Import English locale
import 'dayjs/locale/nb'; // Import Norwegian locale
import { dateLocale } from '../../../main';
import PhoneNumberField from '../../../components/inputs/PhoneNumberField';
import { unwrapResult } from '@reduxjs/toolkit';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  countryCode: string;
  gender: string;
  acceptedTerms: boolean;
};

export interface RegistrationFormProps {
  onSubmitSuccess: (email: string) => void;
  onError: (message: string) => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  onSubmitSuccess,
  onError,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  // We've moved the detectCountryCode function to the shared PhoneNumberField component

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    trigger,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      countryCode: '',
      gender: 'prefer-not-to-say',
      acceptedTerms: false,
    },
    mode: 'onBlur',
  });

  const password = watch('password');
  const email = watch('email');

  // Function to check if email already exists
  const checkEmail = async (email: string) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return;
    }

    setEmailCheckLoading(true);
    try {
      const resultAction = await dispatch(checkEmailExists(email));
      const result = unwrapResult(resultAction);
      setEmailExists(result.exists);
      if (result.exists) {
        setError('email', {
          type: 'manual',
          message: i18next.t('Views.RegisterPage.EmailAlreadyExists'),
        });
      } else {
        if (errors.email?.type === 'manual') {
          clearErrors('email');
        }
      }
    } catch (error) {
      console.error('Failed to check email:', error);
    } finally {
      setEmailCheckLoading(false);
    }
  };

  // Phone number existence check is now handled by the PhoneNumberField component

  const onSubmit = async (data: FormValues) => {
    // Check if email already exists before submitting
    if (emailExists) {
      setError('email', {
        type: 'manual',
        message: i18next.t('Views.RegisterPage.EmailAlreadyExists'),
      });
      return;
    }

    // Phone existence check is now handled by the PhoneNumberField component

    // Perform one final check for email existence
    await checkEmail(data.email);
    if (emailExists) {
      return;
    }

    // Only check if country code is provided when phone number is entered
    if (data.phoneNumber && !data.countryCode) {
      setError('countryCode', {
        type: 'manual',
        message: i18next.t('Views.RegisterPage.CountryCodeRequired'),
      });
      return;
    }

    // No need to check if phone exists here as PhoneNumberField component handles this

    // Prepare registration data
    const registrationData = {
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      gender: data.gender,
      acceptedTerms: data.acceptedTerms,
    };

    // Only include phone number and country code if phone number is provided
    if (data.phoneNumber) {
      Object.assign(registrationData, {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
      });
    }

    try {
      const resultAction = await dispatch(registerUser(registrationData));
      const result = unwrapResult(resultAction);
      console.log('Registration result:', result);
      if (result.success) {
        console.log('Calling onSubmitSuccess with email:', data.email);
        onSubmitSuccess(data.email);
      } else if (result.error) {
        onError(result.error);
      }
    } catch (error) {
      console.error('Registration failed:', error);
      onError(
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : i18next.t('Views.RegisterPage.GenericError')
      );
    }
  };

  // @ts-ignore
  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        backdropFilter: 'blur(10px)',
        p: 3,
        borderRadius: 1,
        border: '1px solid rgba(212, 175, 55, 0.3)',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
        position: 'relative',
        overflow: 'hidden',
        animation: 'formAppear 0.8s ease-out 0.2s both',
        '@keyframes formAppear': {
          from: {
            opacity: 0,
            transform: 'scale(0.95)',
            filter: 'blur(5px)',
          },
          to: {
            opacity: 1,
            transform: 'scale(1)',
            filter: 'blur(0)',
          },
        },
      }}
    >
      <Grid container spacing={2}>
        {/* First Name Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.FirstNameRequired'),
              minLength: {
                value: 2,
                message: i18next.t('Views.RegisterPage.FirstNameMinLength'),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                id="firstName"
                label={i18next.t('Views.RegisterPage.FirstNameLabel')}
                autoComplete="given-name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': {
                      borderColor: errors.firstName
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: errors.firstName
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.firstName ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: errors.firstName
                      ? 'error.main'
                      : 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: errors.firstName ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'error.main',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Last Name Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.LastNameRequired'),
              minLength: {
                value: 2,
                message: i18next.t('Views.RegisterPage.LastNameMinLength'),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                id="lastName"
                label={i18next.t('Views.RegisterPage.LastNameLabel')}
                autoComplete="family-name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': {
                      borderColor: errors.lastName
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: errors.lastName
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.lastName ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: errors.lastName
                      ? 'error.main'
                      : 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: errors.lastName ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'error.main',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Email Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.EmailRequired'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: i18next.t('Views.RegisterPage.EmailInvalid'),
              },
              validate: async (value) => {
                if (emailExists) {
                  return i18next.t('Views.RegisterPage.EmailAlreadyExists');
                }
                await checkEmail(value);
                if (emailExists) {
                  return i18next.t('Views.RegisterPage.EmailAlreadyExists');
                }
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                id="email"
                label={i18next.t('Views.RegisterPage.EmailLabel')}
                autoComplete="email"
                error={!!errors.email || emailExists}
                helperText={
                  errors.email?.message ||
                  (emailExists
                    ? i18next.t('Views.RegisterPage.EmailAlreadyExists')
                    : '')
                }
                onBlur={(e) => {
                  field.onBlur();
                  checkEmail(e.target.value);
                }}
                onChange={(e) => {
                  field.onChange(e);
                  if (emailExists) {
                    // Clear the manual error when user starts typing again
                    if (errors.email?.type === 'manual') {
                      clearErrors('email');
                    }
                    setEmailExists(false);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {emailCheckLoading && <CircularProgress size={20} />}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': {
                      borderColor: errors.email
                        ? 'error.main'
                        : emailExists
                          ? 'error.main'
                          : 'rgba(212, 175, 55, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: errors.email
                        ? 'error.main'
                        : emailExists
                          ? 'error.main'
                          : 'rgba(212, 175, 55, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.email
                        ? 'error.main'
                        : emailExists
                          ? 'error.main'
                          : '#D4AF37',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: errors.email
                      ? 'error.main'
                      : emailExists
                        ? 'error.main'
                        : 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: errors.email
                        ? 'error.main'
                        : emailExists
                          ? 'error.main'
                          : '#D4AF37',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'error.main',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Phone Number Field */}
        <Grid item xs={12} md={6}>
          <PhoneNumberField
            control={control}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            errors={errors}
            required={false}
          />
        </Grid>

        {/* Date of Birth Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.DateOfBirthRequired'),
              validate: (value) => {
                // Check if user is at least 13 years old
                if (value) {
                  const dob = dayjs(value);
                  const minAge = dayjs().subtract(13, 'year');
                  if (dob.isAfter(minAge)) {
                    return i18next.t('Views.RegisterPage.MinAgeError');
                  }
                }
                return true;
              },
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dateLocale}>
                <DatePicker
                  label={i18next.t('Views.RegisterPage.DateOfBirthLabel')}
                  value={field.value ? dayjs(field.value) : null}
                  onChange={(date: Dayjs | null) => {
                    field.onChange(date ? date.format('YYYY-MM-DD') : '');
                  }}
                  disableFuture
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: 'normal',
                      required: true,
                      error: !!errors.dateOfBirth,
                      helperText: errors.dateOfBirth?.message,
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          color: '#fff',
                          '& fieldset': {
                            borderColor: errors.dateOfBirth
                              ? 'error.main'
                              : 'rgba(212, 175, 55, 0.3)',
                          },
                          '&:hover fieldset': {
                            borderColor: errors.dateOfBirth
                              ? 'error.main'
                              : 'rgba(212, 175, 55, 0.5)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: errors.dateOfBirth
                              ? 'error.main'
                              : '#D4AF37',
                          },
                        },
                        '& .MuiInputLabel-root': {
                          color: errors.dateOfBirth
                            ? 'error.main'
                            : 'rgba(255, 255, 255, 0.7)',
                          '&.Mui-focused': {
                            color: errors.dateOfBirth
                              ? 'error.main'
                              : '#D4AF37',
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: '#fff',
                        },
                        '& .MuiFormHelperText-root': {
                          color: 'error.main',
                        },
                        '& .MuiSvgIcon-root': {
                          color: 'rgba(255, 255, 255, 0.7)',
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>

        {/* Gender Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                error={!!errors.gender}
                sx={{
                  mt: 2,
                  '& .MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': {
                      borderColor: errors.gender
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: errors.gender
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.gender ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: errors.gender
                      ? 'error.main'
                      : 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: errors.gender ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiSelect-select': {
                    color: '#fff',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'error.main',
                  },
                }}
              >
                <InputLabel id="gender-label">
                  {i18next.t('Views.RegisterPage.GenderLabel')}
                </InputLabel>
                <Select
                  {...field}
                  labelId="gender-label"
                  id="gender"
                  label={i18next.t('Views.RegisterPage.GenderLabel')}
                >
                  <MenuItem value="male">
                    {i18next.t('Views.RegisterPage.MaleOption')}
                  </MenuItem>
                  <MenuItem value="female">
                    {i18next.t('Views.RegisterPage.FemaleOption')}
                  </MenuItem>
                  <MenuItem value="other">
                    {i18next.t('Views.RegisterPage.OtherOption')}
                  </MenuItem>
                  <MenuItem value="prefer-not-to-say">
                    {i18next.t('Views.RegisterPage.PreferNotToSay')}
                  </MenuItem>
                </Select>
                {errors.gender && (
                  <Typography variant="caption" color="error.main">
                    {errors.gender.message}
                  </Typography>
                )}
              </FormControl>
            )}
          />
        </Grid>

        {/* Password Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.PasswordRequired'),
              minLength: {
                value: 8,
                message: i18next.t('Views.RegisterPage.PasswordMinLength'),
              },
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message: i18next.t('Views.RegisterPage.PasswordRequirements'),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18next.t('Views.RegisterPage.PasswordLabel')}
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': {
                      borderColor: errors.password
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: errors.password
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.password ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: errors.password
                      ? 'error.main'
                      : 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: errors.password ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'error.main',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Confirm Password Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.ConfirmPasswordRequired'),
              validate: (value) =>
                value === password ||
                i18next.t('Views.RegisterPage.PasswordMismatch'),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label={i18next.t('Views.RegisterPage.ConfirmPasswordLabel')}
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': {
                      borderColor: errors.confirmPassword
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: errors.confirmPassword
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.confirmPassword
                        ? 'error.main'
                        : '#D4AF37',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: errors.confirmPassword
                      ? 'error.main'
                      : 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-focused': {
                      color: errors.confirmPassword ? 'error.main' : '#D4AF37',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: '#fff',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'error.main',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 600,
              textTransform: 'none',
              background:
                'linear-gradient(90deg, #D4AF37 0%, #FFD700 50%, #D4AF37 100%)',
              color: 'black',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
              '&:hover': {
                background:
                  'linear-gradient(90deg, #BD9B2B 0%, #EAC500 50%, #BD9B2B 100%)',
                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
                transform: 'translateY(-2px)',
              },
              transition: 'all 0.3s ease',
            }}
          >
            {i18next.t('Views.RegisterPage.RegisterButton')}
          </Button>
        </Grid>

        {/* Terms & Conditions */}
        <Grid item xs={12}>
          <Controller
            name="acceptedTerms"
            control={control}
            rules={{
              required: i18next.t('Views.RegisterPage.AcceptTermsRequired'),
            }}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    sx={{
                      color: errors.acceptedTerms
                        ? 'error.main'
                        : 'rgba(212, 175, 55, 0.8)',
                      '&.Mui-checked': {
                        color: errors.acceptedTerms ? 'error.main' : '#D4AF37',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      color: errors.acceptedTerms
                        ? 'error.main'
                        : 'rgba(255, 255, 255, 0.7)',
                      display: 'inline',
                    }}
                  >
                    {i18next.t('Views.RegisterPage.AcceptTermsLabel')}{' '}
                    <Link
                      href="/terms"
                      target="_blank"
                      sx={{
                        color: errors.acceptedTerms ? 'error.main' : '#D4AF37',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {i18next.t('Views.RegisterPage.TermsAndConditions')}
                    </Link>{' '}
                    {i18next.t('Views.RegisterPage.And')}{' '}
                    <Link
                      href="/privacy"
                      target="_blank"
                      sx={{
                        color: errors.acceptedTerms ? 'error.main' : '#D4AF37',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {i18next.t('Views.RegisterPage.PrivacyPolicy')}
                    </Link>
                  </Typography>
                }
                sx={{
                  alignItems: 'flex-start',
                  mt: 2,
                  marginLeft: 0,
                }}
              />
            )}
          />
          {errors.acceptedTerms && (
            <Typography variant="caption" sx={{ color: 'error.main', ml: 2 }}>
              {errors.acceptedTerms.message}
            </Typography>
          )}
        </Grid>

        {/* Login Link */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Link
                component={RouterLink}
                to="/login"
                variant="body2"
                sx={{
                  color: '#D4AF37',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {i18next.t('Views.RegisterPage.AlreadyHaveAccount')}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegistrationForm;
