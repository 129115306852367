import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import i18next from '../../../utilis/i18next';
import axiosInstance from '../../../utilis/axios';
import { apiUrl } from '../../../main';

interface VerifyPhoneDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  phoneNumber: string;
}

const VerifyPhoneDialog: React.FC<VerifyPhoneDialogProps> = ({
  open,
  onClose,
  onSuccess,
  phoneNumber,
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
    if (error) setError('');
  };

  const handleVerify = async () => {
    if (!verificationCode || verificationCode.length < 6) {
      setError('Please enter a valid verification code');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Submit the verification code to the API
      await axiosInstance.post(`${apiUrl}/users/confirm-phone`, {
        verificationCode,
      });

      // Notify parent component of success
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error verifying phone:', error);
      setError(
        error.response?.data?.message || 'Failed to verify phone number'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ color: '#D4AF37', fontWeight: 500 }}>
        {i18next.t('Components.VerifyPhoneDialog.Title', 'Verify Phone Number')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {i18next.t(
            'Components.VerifyPhoneDialog.Instruction',
            'Enter the 6-digit verification code sent to your WhatsApp account:'
          )}{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {phoneNumber}
          </Box>
        </DialogContentText>
        <DialogContentText sx={{ mb: 2, color: 'text.secondary', fontSize: '0.875rem' }}>
          {i18next.t(
            'Components.VerifyPhoneDialog.WhatsAppOnly',
            'Note: Currently, phone verification is only available through WhatsApp.'
          )}
        </DialogContentText>
        <TextField
          fullWidth
          label={i18next.t(
            'Components.VerifyPhoneDialog.CodeLabel',
            'Verification Code'
          )}
          value={verificationCode}
          onChange={handleCodeChange}
          error={!!error}
          helperText={error}
          inputProps={{ maxLength: 6 }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'rgba(212, 175, 55, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D4AF37',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#D4AF37',
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button
          onClick={onClose}
          sx={{
            color: 'text.secondary',
          }}
        >
          {i18next.t('Common.Cancel', 'Cancel')}
        </Button>
        <Button
          onClick={handleVerify}
          disabled={loading}
          variant="contained"
          sx={{
            bgcolor: '#D4AF37',
            color: '#000',
            fontWeight: 500,
            '&:hover': {
              bgcolor: '#c4a030',
            },
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: '#000' }} />
          ) : (
            i18next.t('Components.VerifyPhoneDialog.VerifyButton', 'Verify')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyPhoneDialog;
