import React from 'react';
import { Paper } from '@mui/material';

const Section = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'rgba(212, 175, 55, 0.2)',
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: '0 8px 24px rgba(212, 175, 55, 0.15)',
        },
      }}
    >
      {children}
    </Paper>
  );
};
export default Section;
