import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import 'dayjs/locale/nb';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import i18next from '../../../utilis/i18next';
import PhoneNumberField from '../../../components/inputs/PhoneNumberField';
import { dateLocale } from '../../../main';
import Section from '../../../components/common/Section.tsx';
import SectionTitle from '../../../components/common/SectionTitle.tsx';
import LoadingSpinner from '../../../components/common/LoadingSpinner.tsx';

// Define the FormValues type to match what's used in the parent component
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  dateOfBirth: string;
  profilePictureId?: string | null;
  phoneNumberConfirmed?: boolean;
}

interface PersonalInformationProps {
  form: UseFormReturn<FormValues>;
  loading: boolean;
  saving: boolean;
  onSubmit: (data: FormValues) => Promise<void>;
  onVerifyPhone: () => Promise<void>;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  form,
  loading,
  saving,
  onSubmit,
  onVerifyPhone,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    clearErrors,
    setError,
    formState: { errors },
  } = form;

  return (
    <Section>
      <SectionTitle
        title={i18next.t(
          'Views.ProfilePage.PersonalInformation',
          'Personal Information'
        )}
      />
      <Box>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={i18next.t(
                        'Views.ProfilePage.FirstName',
                        'First Name'
                      )}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: 'rgba(212, 175, 55, 0.5)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D4AF37',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#D4AF37',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={i18next.t(
                        'Views.ProfilePage.LastName',
                        'Last Name'
                      )}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: 'rgba(212, 175, 55, 0.5)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D4AF37',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#D4AF37',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={i18next.t('Views.ProfilePage.Email', 'Email')}
                      disabled
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: 'rgba(212, 175, 55, 0.5)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D4AF37',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: '#D4AF37',
                        },
                      }}
                      helperText={i18next.t(
                        'Views.ProfilePage.EmailHelperText',
                        'Email cannot be changed'
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneNumberField
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  setError={setError}
                  errors={errors}
                  phoneNumberConfirmed={watch('phoneNumberConfirmed')}
                  helperTextConfirmed={i18next.t(
                    'Views.ProfilePage.PhoneVerified',
                    'Phone number is verified'
                  )}
                  helperTextNotConfirmed={i18next.t(
                    'Views.ProfilePage.PhoneNotVerified',
                    'Phone number is not verified'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mt: 2 }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={dateLocale}
                  >
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          label={i18next.t(
                            'Views.ProfilePage.DateOfBirth',
                            'Date of Birth'
                          )}
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(
                              date ? date.format('YYYY-MM-DD') : ''
                            );
                          }}
                          disableFuture
                          slots={{
                            openPickerIcon: CalendarTodayIcon,
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              variant: 'outlined',
                              InputProps: {
                                sx: {
                                  color: '#fff',
                                },
                              },
                              sx: {
                                '& .MuiOutlinedInput-root': {
                                  '&:hover fieldset': {
                                    borderColor: 'rgba(212, 175, 55, 0.5)',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#D4AF37',
                                  },
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                  color: '#D4AF37',
                                },
                                '& .MuiSvgIcon-root': {
                                  color: 'rgba(255, 255, 255, 0.7)',
                                },
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {!watch('phoneNumberConfirmed') && watch('phoneNumber') && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      mb: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={onVerifyPhone}
                      title={i18next.t(
                        'Views.ProfilePage.VerifyPhoneTooltip',
                        'Verify your phone number via WhatsApp'
                      )}
                      sx={{
                        padding: '6px 16px',
                        border: '1px solid rgba(212, 175, 55, 0.7)',
                        color: '#D4AF37',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          borderColor: '#D4AF37',
                          backgroundColor: 'rgba(212, 175, 55, 0.05)',
                        },
                        fontSize: '0.875rem',
                        textTransform: 'none',
                        fontWeight: 500,
                      }}
                    >
                      {i18next.t(
                        'Common.VerifyViaWhatsApp',
                        'Verify via WhatsApp'
                      )}
                    </Button>
                    <Typography
                      variant="caption"
                      sx={{ mt: 0.5, color: 'text.secondary' }}
                    >
                      {i18next.t(
                        'Views.ProfilePage.VerifyPhoneNote',
                        'A verification code will be sent to your WhatsApp account'
                      )}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={saving}
                    sx={{
                      minWidth: 150,
                      bgcolor: '#D4AF37',
                      color: '#000',
                      fontWeight: 500,
                      py: 1.5,
                      '&:hover': {
                        bgcolor: '#c4a030',
                      },
                      '&:disabled': {
                        bgcolor: 'rgba(212, 175, 55, 0.3)',
                      },
                    }}
                  >
                    {saving ? (
                      <CircularProgress size={24} sx={{ color: '#000' }} />
                    ) : (
                      i18next.t('Views.ProfilePage.SaveButton', 'Save Changes')
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default PersonalInformation;
