// authSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utilis/axios';
import { AuthResponse } from '../../types/states/AuthState';
import { NotificationSettings } from '../../types/NotificationTypes';
import { apiUrl } from '../../main.tsx';

// Helper function to validate token format
const isValidJwtToken = (token: string | null): boolean => {
  return token !== null && token.split('.').length === 3;
};

// Get stored token and validate it
const storedToken = localStorage.getItem('accessToken');
const validatedToken = isValidJwtToken(storedToken) ? storedToken : null;

// If token is invalid, clear localStorage to prevent future errors
if (storedToken && !validatedToken) {
  console.warn('Invalid token found in localStorage, clearing auth data');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
}

const initialState: AuthResponse = {
  accessToken: validatedToken,
  refreshToken: validatedToken ? localStorage.getItem('refreshToken') : null,
  user:
    validatedToken && localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') || 'null')
      : null,
  status: 'idle',
  error: null,
  message: null,
  expiresIn: null,
  success: false,
};

export const login = createAsyncThunk(
  'auth/login',
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post('/auth/login', credentials);
      const data = response.data;

      // Check if we have a valid token in the response
      const token = data.accessToken || data.token;
      if (
        !token ||
        typeof token !== 'string' ||
        token.split('.').length !== 3
      ) {
        console.error('Invalid token format received from login API:', token);
        return rejectWithValue(
          'Invalid authentication token received from server'
        );
      }

      // Ensure the token is properly formatted before returning
      return {
        ...data,
        accessToken: token, // Normalize to accessToken
        token: token, // Keep token for backward compatibility
      };
    } catch (error: any) {
      console.error('Login error:', error.response?.data || error.message);
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Login failed'
      );
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (userData: {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumber?: string;
    countryCode?: string;
    gender?: string;
    preferredTitle?: string;
    acceptedTerms: boolean;
  }) => {
    try {
      const response = await axiosInstance.post(
        apiUrl + '/auth/register',
        userData
      );
      console.log('Registration API response:', response.data);
      return { ...response.data, success: true };
    } catch (error) {
      console.error('Registration API error:', error);
      throw error;
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/confirmEmail',
  async (data: { token: string; userId: string }) => {
    const response = await axiosInstance.get(
      apiUrl + `/auth/confirm-email?token=${data.token}&userId=${data.userId}`
    );
    return response.data;
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string) => {
    const response = await axiosInstance.post(
      apiUrl + '/auth/forgot-password',
      {
        email,
      }
    );
    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: {
    token: string;
    userId: string;
    password: string;
    confirmPassword: string;
  }) => {
    const response = await axiosInstance.post(
      apiUrl + `/auth/reset-password?token=${data.token}&userId=${data.userId}`,
      {
        password: data.password,
        confirmPassword: data.confirmPassword,
      }
    );
    return response.data;
  }
);

export const checkEmailExists = createAsyncThunk(
  'auth/checkEmail',
  async (email: string) => {
    const response = await axiosInstance.post(
      apiUrl + '/auth/check-email-exists',
      { email }
    );
    return response.data;
  }
);

export const resendConfirmationEmail = createAsyncThunk(
  'auth/resendConfirmationEmail',
  async (email: string) => {
    try {
      const response = await axiosInstance.post(
        apiUrl + '/auth/resend-confirmation-email',
        { email }
      );
      console.log('Resend confirmation email response:', response.data);
      return { ...response.data, success: true };
    } catch (error) {
      console.error('Resend confirmation email error:', error);
      throw error;
    }
  }
);

export const checkPhoneExists = createAsyncThunk(
  'auth/checkPhone',
  async (data: { phoneNumber: string; countryCode?: string }) => {
    const response = await axiosInstance.post(
      apiUrl + '/auth/check-phone-exists',
      data
    );
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.status = 'idle';
      state.error = null;
      state.message = null;
      state.success = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Token validation is now done in the login thunk
        const token = action.payload.accessToken || action.payload.token;

        state.accessToken = token;
        state.refreshToken = action.payload.refreshToken;
        state.user = action.payload.user;
        state.expiresIn = action.payload.expiresIn;
        state.success = action.payload.success;

        // Store the token in localStorage
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', action.payload.refreshToken);
        localStorage.setItem('user', JSON.stringify(action.payload.user));

        console.log('Token stored successfully');

        // Clear any previous errors
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Login failed';
      })
      .addCase(register.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.message = 'Registration successful';
        state.success = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Registration failed';
      })
      .addCase(confirmEmail.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(confirmEmail.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Email confirmation failed';
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Password reset request failed';
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Password reset failed';
      })
      .addCase(checkEmailExists.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(checkEmailExists.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(checkEmailExists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Email check failed';
      })
      .addCase(resendConfirmationEmail.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(resendConfirmationEmail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.success = true;
        state.message =
          action.payload.message || 'Confirmation email resent successfully';
      })
      .addCase(resendConfirmationEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error =
          action.error.message || 'Failed to resend confirmation email';
      })
      .addCase(checkPhoneExists.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(checkPhoneExists.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(checkPhoneExists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Phone check failed';
      })
      // Handle fetchUserProfile action
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update user data in state only if there are actual changes
        if (action.payload) {
          // Check if we need to update the user data (prevent unnecessary state updates)
          const hasChanges =
            !state.user ||
            Object.keys(action.payload).some(
              (key) => action.payload[key] !== state.user?.[key]
            );

          if (hasChanges) {
            state.user = {
              ...state.user,
              ...action.payload,
            };

            // Update user data in localStorage
            try {
              const storedUser = localStorage.getItem('user');
              if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                const updatedUser = { ...parsedUser, ...action.payload };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                console.log('User profile updated in localStorage after fetch');
              }
            } catch (error) {
              console.error('Error updating localStorage:', error);
            }
          }
        }
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch user profile';
      })
      // Handle updateUserProfile action
      .addCase(updateUserProfile.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Update user data in state only if there are actual changes
        if (action.payload) {
          // Check if we need to update the user data (prevent unnecessary state updates)
          const hasChanges =
            !state.user ||
            Object.keys(action.payload).some(
              (key) => action.payload[key] !== state.user?.[key]
            );

          if (hasChanges) {
            state.user = {
              ...state.user,
              ...action.payload,
            };

            // Update user data in localStorage
            try {
              const storedUser = localStorage.getItem('user');
              if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                const updatedUser = { ...parsedUser, ...action.payload };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                console.log(
                  'User profile updated in localStorage after update'
                );
              }
            } catch (error) {
              console.error('Error updating localStorage:', error);
            }
          }
        }
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update user profile';
      })
      // Handle updateNotificationPreferences actions
      .addCase(updateNotificationPreferences.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNotificationPreferences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user.notificationPreferences = action.payload;
        // Update localStorage
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(updateNotificationPreferences.rejected, (state, action) => {
        state.status = 'failed';
        state.error =
          action.error.message || 'Failed to update notification preferences';
      });
  },
});

export const fetchUserProfile = createAsyncThunk(
  'auth/fetchUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/users/profile');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch user profile'
      );
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async (
    profileData: {
      firstName: string;
      lastName: string;
      phoneNumber?: string;
      dateOfBirth?: string | Date;
      profilePictureId?: string | null;
      notificationPreferences?: NotificationSettings;
    },
    { rejectWithValue }
  ) => {
    try {
      await axiosInstance.put('/users/profile', profileData);

      // After successful update, fetch the updated profile
      const response = await axiosInstance.get('/users/profile');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to update user profile'
      );
    }
  }
);

// Thunk action to update just notification preferences
export const updateNotificationPreferences = createAsyncThunk(
  'auth/updateNotificationPreferences',
  async (
    notificationPreferences: NotificationSettings,
    { rejectWithValue, getState }
  ) => {
    console.log(
      'updateNotificationPreferences thunk called with:',
      notificationPreferences
    );
    try {
      // Use the dedicated endpoint for notification preferences
      // Send NotificationPreferences property as expected by the backend DTO
      await axiosInstance.put('/users/notification-preferences', {
        NotificationPreferences: notificationPreferences,
      });

      return { notificationPreferences };
    } catch (error) {
      // Error handling
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.message ||
          'Failed to update notification preferences';
        return rejectWithValue(errorMessage);
      }
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

export const { clearAuthState } = authSlice.actions;

export const selectAuth = (state: { auth: AuthResponse }) => state.auth;

export default authSlice.reducer;
