import React from 'react';
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import i18next from '../../../utilis/i18next';
import AvatarUpload from './AvatarUpload';
import { User } from '../../../types/user';

interface ProfileSummaryCardProps {
  user: User;
  profilePictureId: string | null;
  firstName: string;
  lastName: string;
  onImageChange: (imageId: string | null) => void;
  onRemoveProfilePicture: () => void;
  getInitials: () => string;
}

const ProfileSummaryCard: React.FC<ProfileSummaryCardProps> = ({
  user,
  profilePictureId,
  firstName,
  lastName,
  onImageChange,
  onRemoveProfilePicture,
  getInitials,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'rgba(212, 175, 55, 0.2)',
        transition: 'all 0.3s ease',
        '&:hover': {
          boxShadow: '0 8px 24px rgba(212, 175, 55, 0.15)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <AvatarUpload
          initialImageId={profilePictureId}
          onImageChange={onImageChange}
          initials={getInitials()}
          name={`${firstName} ${lastName}`}
        />

        {/* Remove Photo Button */}
        {profilePictureId && (
          <Button
            variant="text"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={onRemoveProfilePicture}
            size="small"
            sx={{ mt: 1 }}
          >
            Remove Photo
          </Button>
        )}
      </Box>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ color: '#D4AF37', fontWeight: 500 }}
      >
        {user.fullName ||
          `${user.firstName} ${user.lastName}`}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 2, color: 'text.secondary' }}
      >
        {user.email}
      </Typography>
      {user.roles && user.roles.length > 0 && (
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {user.roles.map((role) => (
            <Box
              key={role}
              sx={{
                px: 2,
                py: 0.5,
                bgcolor: 'rgba(212, 175, 55, 0.1)',
                borderRadius: 4,
                border: '1px solid',
                borderColor: 'rgba(212, 175, 55, 0.3)',
                color: '#D4AF37',
                fontSize: '0.875rem',
                fontWeight: 500,
              }}
            >
              {role}
            </Box>
          ))}
        </Box>
      )}
    </Paper>
  );
};

export default ProfileSummaryCard;
