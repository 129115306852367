import axios from 'axios';
import axiosRetry from 'axios-retry';
// import store from '../store/store';
import { clearAuthState } from '../store/slices/authSlice';

const API_URL = import.meta.env.VITE_API_URL;
console.log(API_URL);
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosRetry(axiosInstance, {
  retries: 1,
  retryCondition: (error) => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      error.response?.status === 401
    );
  },
});

// Helper function to validate JWT token format
const isValidJwtToken = (token: string | null): boolean => {
  return token !== null && typeof token === 'string' && token.split('.').length === 3;
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    
    // Only use token if it's valid JWT format (has two dots)
    if (token && isValidJwtToken(token) && !config.url?.includes('/auth/login')) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Using valid token for request:', config.url);
    } else if (token && !isValidJwtToken(token)) {
      console.warn('Invalid token format detected, not sending Authorization header');
      // Clear invalid token
      localStorage.removeItem('accessToken');
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401 and we haven't retried yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Attempt to refresh the token
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }
        
        const response = await axiosInstance.post('/auth/refresh-token', { refreshToken });
        const newToken = response.data.accessToken || response.data.token;

        // Validate and update the token in localStorage
        if (newToken && isValidJwtToken(newToken)) {
          console.log('Token refreshed successfully');
          localStorage.setItem('accessToken', newToken);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        } else {
          console.error('Received invalid token format during refresh');
          throw new Error('Invalid token received during refresh');
        }
      } catch (refreshError) {
        // If refresh token fails, clear auth state and redirect to login
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        
        // Redirect to login page if this is a browser environment
        if (typeof window !== 'undefined') {
          window.location.href = '/login';
        }
        
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
